import UnauthenticatedOnly from '../components/UnauthenticatedOnly';
import AuthPageWrapper from '../components/AuthPageWrapper';
import { Outlet } from 'react-router-dom';

const AuthRootLayout = () => (
  <UnauthenticatedOnly>
    <AuthPageWrapper>
      <Outlet />
    </AuthPageWrapper>
  </UnauthenticatedOnly>
);

export default AuthRootLayout;
