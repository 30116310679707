import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

const StyledAddButton = styled(Button)({
  marginTop: 4,
  width: 'calc(100% - 40px)',
});

const AddButton = (props: Record<string, unknown>) => (
  <StyledAddButton
    variant="outlined"
    color="secondary"
    startIcon={<AddIcon />}
    {...props}
  >
    Add allowed e-mail domain
  </StyledAddButton>
);

export default AddButton;
