import styled from '@emotion/styled';
import { CARD_WIDTH } from '../PerspectiveCard/styleConstants';

const OuterChunkWrapper = styled('div')({
  position: 'relative',
  margin: -12,
  padding: 12,
  width: CARD_WIDTH,
  minWidth: CARD_WIDTH,
  maxWidth: CARD_WIDTH,
  overflow: 'hidden',
});

export default OuterChunkWrapper;
