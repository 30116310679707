import Space from '@/components/Space';
import useSwrMutate from '@/lib/useSwrMutate';
import { SuperUser } from '@/routes/super-users/types';
import { Mail as InviteIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { SuperUsersMap } from '..';
import api from '@/lib/api';

type InviteSuperUserProps = {
  superUser: SuperUser;
};

const InviteSuperUser = ({ superUser }: InviteSuperUserProps) => {
  const [inviting, setInviting] = useState(false);

  const mutate = useSwrMutate<SuperUsersMap>('/users');

  const sendInvite = async () => {
    setInviting(true);

    await api.post(`/users/${superUser.id}/invite`);

    mutate(superUsers => {
      if (!superUsers?.[superUser.id]) {
        return superUsers;
      }

      return {
        ...superUsers,
        [superUser.id]: {
          ...superUsers[superUser.id],
          invitable: false,
        },
      };
    });

    setInviting(false);
  };

  return (
    <>
      <LoadingButton
        startIcon={<InviteIcon />}
        loadingPosition="start"
        loading={inviting}
        onClick={sendInvite}
      >
        Send invite
      </LoadingButton>
      <Space width={12} />
    </>
  );
};

export default InviteSuperUser;
