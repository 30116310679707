import React from 'react';
import FormWrapper from './FormWrapper';
import AllowedEmailTextField from '../AllowedEmailTextField';
import { IconButton } from '@mui/material';
import { Close as DeleteIcon } from '@mui/icons-material';
import Space from '@/components/Space';
import { AllowedEmail } from '@/routes/schools/types';

type AllowedEmailFormProps = {
  allowedEmail: AllowedEmail;
  onEdit: (allowedEmail: AllowedEmail) => void;
  onRemove: () => void;
};

const AllowedEmailForm = ({
  allowedEmail,
  onEdit,
  onRemove,
  ...props
}: AllowedEmailFormProps) => {
  const onEditAllowedEmailPrefix = (e: React.ChangeEvent<HTMLInputElement>) =>
    onEdit([e.target.value.trim(), allowedEmail[1]]);

  const onEditAllowedEmailDomain = (e: React.ChangeEvent<HTMLInputElement>) =>
    onEdit([allowedEmail[0], e.target.value.trim()]);

  return (
    <FormWrapper {...props}>
      <AllowedEmailTextField
        value={allowedEmail[0]}
        onChange={onEditAllowedEmailPrefix}
        asPrefixField
      />
      <Space width={8} />
      <AllowedEmailTextField
        value={allowedEmail[1]}
        onChange={onEditAllowedEmailDomain}
      />
      <IconButton color="error" onClick={onRemove}>
        <DeleteIcon />
      </IconButton>
    </FormWrapper>
  );
};

export default AllowedEmailForm;
