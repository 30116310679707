import { Perspective } from '../../types';
import PerspectiveManagerWrapper from './PerspectiveManagerWrapper';
import IndividualPerspective from './IndividualPerspective';
import { useRef, useState } from 'react';
import PerspectiveSublevels from './PerspectiveSublevels';
import PerspectiveManagerWrapperScrollContext from './PerspectiveManagerWrapperScrollContext';
import PerspectiveColumn from './PerspectiveColumn';
import { flushSync } from 'react-dom';

type PerspectiveManagerProps = {
  perspectives: Perspective[];
};

const getOpenPerspective = (
  perspectives: Perspective[],
  openPerspectiveId: number | null,
): Perspective | null => {
  if (openPerspectiveId === null) {
    return null;
  }

  return (
    perspectives.find(perspective => perspective.id === openPerspectiveId) ??
    null
  );
};

// TODO handle long titles better
// TODO error states
// TODO allow deletion when a) no children b) <= 10 connected tasks
// TODO also allow individual perspective deletion when
// attachedchallenges <= 10 (implement later)
const PerspectiveManager = ({ perspectives }: PerspectiveManagerProps) => {
  const wrapperRef = useRef<HTMLElement>(null);

  const scrollWrapperToRight = () => {
    const wrapper = wrapperRef.current;

    if (wrapper === null) {
      return;
    }

    wrapper.scrollTo({
      left: wrapper.getBoundingClientRect().width,
      behavior: 'smooth',
    });
  };

  const [openPerspectiveId, setOpenPerspectiveId] = useState<number | null>(
    null,
  );
  const [openPerspectiveIndex, setOpenPerspectiveIndex] = useState<
    number | null
  >(null);

  const openPerspective = getOpenPerspective(perspectives, openPerspectiveId);

  const closeOpenPerspective = () => {
    setOpenPerspectiveId(null);
    setOpenPerspectiveIndex(null);
  };

  const handleOpenPerspective = (id: number, index: number) => {
    if (id === openPerspectiveId) {
      closeOpenPerspective();

      return;
    }

    // This prevents weird "jumping" in the connector lines
    flushSync(() => {
      closeOpenPerspective();
    });

    setOpenPerspectiveId(id);
    setOpenPerspectiveIndex(index);
  };

  return (
    <PerspectiveManagerWrapperScrollContext.Provider
      value={scrollWrapperToRight}
    >
      <PerspectiveManagerWrapper ref={wrapperRef}>
        <PerspectiveColumn
          perspectives={perspectives}
          onMove={closeOpenPerspective}
        >
          {perspectives =>
            perspectives.map((perspective, i) => (
              <IndividualPerspective
                perspective={perspective}
                onOpen={() => handleOpenPerspective(perspective.id, i)}
                open={openPerspectiveId === perspective.id}
                faded={
                  openPerspectiveId !== null &&
                  openPerspectiveId !== perspective.id
                }
                key={perspective.id}
              />
            ))
          }
        </PerspectiveColumn>
        {openPerspective && openPerspectiveIndex !== null && (
          <PerspectiveSublevels
            perspective={openPerspective}
            perspectiveIndex={openPerspectiveIndex}
          />
        )}
      </PerspectiveManagerWrapper>
    </PerspectiveManagerWrapperScrollContext.Provider>
  );
};

export default PerspectiveManager;
