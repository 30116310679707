import { ButtonProps } from '@mui/material';
import DialogButton from './DialogButton';

const DialogDeleteButton = ({ children, ...props }: ButtonProps) => (
  <DialogButton color="error" {...props}>
    {children ?? 'Yes, delete'}
  </DialogButton>
);

export default DialogDeleteButton;
