import styled from '@emotion/styled';

const ListItemWrapper = styled('li')(({ theme }) => ({
  padding: '12px 18px',
  display: 'flex',
  alignItems: 'center',
  borderBottom: `2px solid ${theme.palette.divider}`,
  ':last-child': {
    borderBottom: 'none',
  },
}));

export default ListItemWrapper;
