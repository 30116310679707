import UserHeader from './UserHeader';
import ShortcutTiles from './ShortcutTiles';
import NoAppTitle from '@/components/NoAppTitle';

const Home = () => (
  <>
    <NoAppTitle />
    <UserHeader />
    <ShortcutTiles />
  </>
);

export default Home;
