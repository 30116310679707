import LoginFlow from './LoginFlow';
import { useContext } from 'react';
import AuthenticationStateContext from '@/components/AuthenticationState/AuthenticationStateContext';
import api from '@/lib/api';
import AppTitle from '@/components/AppTitle';

const Login = () => {
  const [, refreshAuthenticationState] = useContext(AuthenticationStateContext);

  const handleLoginComplete = (accessToken: string): void => {
    api.authenticate(accessToken);

    refreshAuthenticationState();
  };

  return (
    <>
      <AppTitle>Sign in</AppTitle>
      <LoginFlow onComplete={handleLoginComplete} />
    </>
  );
};

export default Login;
