import { PerspectiveCohort } from '../../types';
import { Button } from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

type PerspectiveCohortManageButtonProps = {
  perspectiveCohort: PerspectiveCohort;
};

const PerspectiveCohortManageButton = ({
  perspectiveCohort,
}: PerspectiveCohortManageButtonProps) => (
  <Button
    variant="outlined"
    color="secondary"
    startIcon={<SettingsIcon />}
    disableElevation
    component={Link}
    to={`/perspectives/${perspectiveCohort.id}`}
  >
    Manage
  </Button>
);

export default PerspectiveCohortManageButton;
