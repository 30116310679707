import React from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { common } from '@mui/material/colors';

type ThemeProviderProps = {
  children: React.ReactNode;
};

const theme = createTheme({
  palette: {
    secondary: {
      main: common.black,
    },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
});

const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <MuiThemeProvider theme={theme}>
    <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
  </MuiThemeProvider>
);

export default ThemeProvider;
