import { useContext } from 'react';
import AuthenticationStateContext from './AuthenticationStateContext';
import { SuperUser } from '@/routes/super-users/types';

const useAuthenticatedSuperUser = (): SuperUser | null => {
  const [authenticatedSuperUser] = useContext(AuthenticationStateContext);

  return authenticatedSuperUser;
};

export default useAuthenticatedSuperUser;
