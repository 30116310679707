import React, { useState } from 'react';
import FlexWrapper from '@/components/FlexWrapper';
import Space from '@/components/Space';
import { Button, TextField } from '@mui/material';
import validator from 'validator';
import ApiError from '@/lib/api/ApiError';
import api from '@/lib/api';
import { LoadingButton } from '@mui/lab';

type MfaOtpConfirmProps = {
  inviteToken: string;
  onGoBack: () => void;
  onDone: () => void;
};

const MfaOtpConfirm = ({
  inviteToken,
  onGoBack,
  onDone,
}: MfaOtpConfirmProps) => {
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setOtpError(null);

    if (otp.length === 0) {
      setOtpError('Please enter your One-Time Password');

      return;
    }

    if (!validator.isInt(otp)) {
      setOtpError('Please enter a valid One-Time Password');

      return;
    }

    setLoading(true);

    try {
      await api.post('/auth/setup/mfa', {
        inviteToken,
        otp,
      });

      onDone();
    } catch (error) {
      if (!(error instanceof ApiError)) {
        throw error;
      }

      setOtpError('Incorrect One-Time Password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h1>Complete MFA setup</h1>
      <p>Enter the One-Time Password displayed in the Authenticator app.</p>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="filled"
          name="otp"
          label="One-Time Password"
          value={otp}
          onChange={e => setOtp(e.target.value)}
          error={otpError !== null}
          helperText={otpError}
          type="number"
          autoFocus
          fullWidth
        />
        <Space height={12} />
        <FlexWrapper>
          <Button variant="outlined" color="secondary" onClick={onGoBack}>
            Back
          </Button>
          <Space width="auto" />
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            disableElevation
          >
            Finish
          </LoadingButton>
        </FlexWrapper>
      </form>
    </>
  );
};

export default MfaOtpConfirm;
