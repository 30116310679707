import styled from '@emotion/styled';
import stripProps from '@/lib/stripProps';
import { CircularProgress, CircularProgressProps } from '@mui/material';

type LoadingSpinnerProps = {
  centered?: boolean;
  margin?: number | string;
  marginTop?: number | string;
  marginBottom?: number | string;
};

const StyledCircularProgress = styled(
  stripProps(
    CircularProgress,
    'centered',
    'margin',
    'marginTop',
    'marginBottom',
  ),
)<LoadingSpinnerProps & CircularProgressProps>(
  ({ margin, marginTop, marginBottom }) => ({
    marginTop: marginTop ?? margin,
    marginBottom: marginBottom ?? margin,
  }),
  ({ centered }) => {
    if (centered) {
      return {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
      };
    }
  },
);

const LoadingSpinner = (
  props: LoadingSpinnerProps & Record<string, unknown>,
) => <StyledCircularProgress size={32} disableShrink {...props} />;

export default LoadingSpinner;
