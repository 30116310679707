import MainPageTitle from '@/components/MainPageTitle';
import HomeButton from './HomeButton';

const NotFoundContent = () => (
  <>
    <MainPageTitle>Page not found</MainPageTitle>
    <HomeButton />
  </>
);

export default NotFoundContent;
