import { SuperUser } from '@/routes/super-users/types';
import { useState } from 'react';
import { IconButton } from '@mui/material';
import { DeleteForever as DeleteIcon } from '@mui/icons-material';
import Dialog from '@/components/Dialog';
import api from '@/lib/api';
import useSwrMutate from '@/lib/useSwrMutate';
import { SuperUsersMap } from '..';

type DeleteSuperUserProps = {
  superUser: SuperUser;
  disabled: boolean;
};

const DeleteSuperUser = ({ superUser, disabled }: DeleteSuperUserProps) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const mutate = useSwrMutate<SuperUsersMap>('/users');

  const deleteSuperUser = async () => {
    setConfirmationDialogOpen(false);
    setDeleting(true);

    await api.delete(`/users/${superUser.id}`);

    mutate(superUsers => {
      const updatedSuperUsers = { ...superUsers };

      delete updatedSuperUsers[superUser.id];

      return updatedSuperUsers;
    });

    setDeleting(false);
  };

  return (
    <>
      <IconButton
        color="error"
        onClick={() => setConfirmationDialogOpen(true)}
        disabled={disabled || deleting}
      >
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          Are you sure you want to remove {superUser.profile.firstName}?
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            {superUser.profile.name} will permanently lose access to Egodact
            Admin, until they're invited again.
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteSuperUser} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeleteSuperUser;
