import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { CARD_HEIGHT, CARD_MARGIN_BOTTOM, CARD_WIDTH } from './styleConstants';

type PerspectiveCardProps = {
  faded?: boolean;
};

const PaperAsLi = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  faded,
  ...props
}: Record<string, unknown> & PerspectiveCardProps) => (
  <Paper component="li" {...props} />
);

const PerspectiveCard = styled(PaperAsLi)<PerspectiveCardProps>(
  ({ theme, faded }) => ({
    position: 'relative',
    marginBottom: CARD_MARGIN_BOTTOM,
    width: CARD_WIDTH,
    height: CARD_HEIGHT,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: theme.transitions.create(['opacity']),
    opacity: faded ? 0.4 : undefined,
    overflow: 'hidden',
    ':last-child': {
      marginBottom: 0,
    },
    ':hover, :focus-within': {
      opacity: 1,
    },
  }),
);

export default PerspectiveCard;
