import styled from '@emotion/styled';
import {
  CONNECTOR_THICKNESS,
  CONNECTOR_COLOR,
  HIGHLIGHTED_CONNECTOR_COLOR,
} from './styleConstants';
import {
  CARD_HEIGHT,
  CARD_MARGIN_BOTTOM,
} from '../../PerspectiveCard/styleConstants';

type StraightCardConnectorProps = {
  index: number;
  highlighted: boolean;
};

const StraightCardConnector = styled('div')<StraightCardConnectorProps>(
  ({ index, highlighted }) => ({
    position: 'absolute',
    top: CARD_HEIGHT / 2,
    left: 0,
    height:
      (index + 1) * CARD_HEIGHT +
      index * CARD_MARGIN_BOTTOM -
      CARD_HEIGHT -
      CONNECTOR_THICKNESS / 2,
    width: '100%',
    borderBottom: `${CONNECTOR_THICKNESS}px solid ${CONNECTOR_COLOR}`,
    borderColor: highlighted ? HIGHLIGHTED_CONNECTOR_COLOR : undefined,
    zIndex: highlighted ? 2 : 1,
  }),
);

export default StraightCardConnector;
