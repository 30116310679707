import { useContext } from 'react';
import PerspectiveCohortContext from '../PerspectiveCohortContext';

const DELETABLE_MAX_ATTACHED_TASKS = 10;

const useIsDeletable = (
  childCount: number,
  attachedTasksCount: number,
): boolean => {
  const perspectiveCohort = useContext(PerspectiveCohortContext);
  const perspectiveCohortIsPublished =
    perspectiveCohort.publishAt !== null &&
    new Date(perspectiveCohort.publishAt) < new Date();

  if (!perspectiveCohortIsPublished) {
    return true;
  }

  return childCount === 0 && attachedTasksCount <= DELETABLE_MAX_ATTACHED_TASKS;
};

export default useIsDeletable;
