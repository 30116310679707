import React from 'react';
import CardConnectors from './CardConnectors';
import StyledColumn from './StyledColumn';

type PerspectiveManagerColumnProps = {
  parentCardIndex?: number | null;
  highlightedConnector?: number | null;
  hidden?: boolean;
  children: React.ReactNode;
};

// TODO maybe just pass prop for this count
const getCardCount = (children: React.ReactNode): number | null => {
  if (
    typeof children === 'undefined' ||
    children === null ||
    !Array.isArray(children) ||
    !Array.isArray(children[0])
  ) {
    return null;
  }

  return children[0].length;
};

// TODO children prop extraction is unnecessary, also maybe move styled column
// wrapper to own file
// TODO maybe also use separate version with & without card connectors?
const PerspectiveManagerColumn = ({
  parentCardIndex,
  highlightedConnector,
  hidden,
  children,
  ...props
}: PerspectiveManagerColumnProps) => {
  const cardCount = getCardCount(children);

  const isRootColumn =
    typeof parentCardIndex === 'undefined' || parentCardIndex === null;

  return (
    <>
      {!isRootColumn && cardCount !== null && (
        <CardConnectors
          cardCount={cardCount}
          parentCardIndex={parentCardIndex}
          highlightedConnector={highlightedConnector ?? null}
        />
      )}
      <StyledColumn
        className="perspective-manager-column"
        hidden={hidden ?? false}
        {...props}
      >
        {children}
      </StyledColumn>
    </>
  );
};

export default PerspectiveManagerColumn;
