import Space from '@/components/Space';
import { Alert } from '@mui/material';

type SaveErrorAlertProps = {
  errorCode: string;
};

const SaveErrorAlert = ({ errorCode }: SaveErrorAlertProps) => (
  <>
    <Alert severity="error">
      Failed to save changes with error code: {errorCode}. Try again later.
    </Alert>
    <Space height={16} />
  </>
);

export default SaveErrorAlert;
