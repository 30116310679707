import { Perspective } from '@/routes/perspectives/types';
import PerspectiveCard from '../PerspectiveCard';
import PerspectiveCardContent from '../PerspectiveCard/PerspectiveCardContent';
import PerspectiveCardHeader from '../PerspectiveCard/PerspectiveCardHeader';
import PerspectiveCardIcon from '../PerspectiveCard/PerspectiveCardIcon';
import PerspectiveCardColorFill from '../PerspectiveCard/PerspectiveCardColorFill';
import { IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import ColorPicker from './ColorPicker';
import { useState } from 'react';
import PerspectiveNameEditor from './PerspectiveNameEditor';
import OpenSublevelsButton from './OpenSublevelsButton';
import DeletePerspective from './DeletePerspective';
import PerspectiveExplanationManager from './PerspectiveExplanationManager';
import PerspectiveIcon from './PerspectiveIcon';

type IndividualPerspectiveProps = {
  perspective: Perspective;
  onOpen: () => void;
  open: boolean;
  faded: boolean;
};

const IndividualPerspective = ({
  perspective,
  onOpen,
  open,
  faded,
}: IndividualPerspectiveProps) => {
  const [nameEditorOpen, setNameEditorOpen] = useState(false);

  return (
    <PerspectiveCard faded={faded}>
      <PerspectiveCardContent>
        <PerspectiveCardHeader>
          {nameEditorOpen ? (
            <PerspectiveNameEditor
              perspective={perspective}
              onClose={() => setNameEditorOpen(false)}
            />
          ) : (
            <>
              <h4>{perspective.name}</h4>
              <IconButton
                className="visible-on-hover"
                size="small"
                onClick={() => setNameEditorOpen(true)}
              >
                <EditIcon />
              </IconButton>
              <PerspectiveExplanationManager perspective={perspective} />
              <DeletePerspective perspective={perspective} />
            </>
          )}
        </PerspectiveCardHeader>
        <PerspectiveCardIcon>
          <PerspectiveIcon perspective={perspective} />
        </PerspectiveCardIcon>
        <OpenSublevelsButton
          onClick={onOpen}
          asCloseButton={open}
          hidden={nameEditorOpen}
        />
      </PerspectiveCardContent>
      <PerspectiveCardColorFill color={perspective.color}>
        <ColorPicker perspective={perspective} />
      </PerspectiveCardColorFill>
    </PerspectiveCard>
  );
};

export default IndividualPerspective;
