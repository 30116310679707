import validator from 'validator';

const validatePassword = (password: string): string | null => {
  if (password.length === 0) {
    return 'Please enter a password';
  }

  if (!validator.isStrongPassword(password)) {
    return 'Please enter a stronger password';
  }

  return null;
};

export default validatePassword;
