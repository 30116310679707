import styled from '@emotion/styled';

const ProfileSettingLabel = styled('label')({
  height: 40,
  width: 120,
  display: 'inline-flex',
  alignItems: 'center',
});

export default ProfileSettingLabel;
