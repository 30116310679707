import DialogButton from './DialogButton';
import { ButtonProps } from '@mui/material';

const DialogOkButton = ({ children, ...props }: ButtonProps) => (
  <DialogButton color="primary" {...props}>
    {children ?? 'Ok'}
  </DialogButton>
);

export default DialogOkButton;
