import LoadingSpinner from '@/components/LoadingSpinner';
import useSwr from '@/lib/useSwr';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import SchoolUserCount from './SchoolUserCount';
import SchoolDetails from './SchoolDetails';
import SchoolCount from './SchoolCount';
import { School } from '../../types';

const SchoolsOverview = () => {
  const { isLoading, data: schools, error } = useSwr<School[]>('/schools');

  if (isLoading) {
    return <LoadingSpinner margin={48} centered />;
  }

  if (error || !schools) {
    return (
      <Alert severity="error">
        Something went wrong while trying to load schools.
      </Alert>
    );
  }

  return (
    <>
      {schools.map(school => (
        <Accordion key={school.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <strong>{school.displayName}</strong>
            <SchoolUserCount>{school._count.users} users</SchoolUserCount>
          </AccordionSummary>
          <AccordionDetails>
            <SchoolDetails school={school} />
          </AccordionDetails>
        </Accordion>
      ))}
      <SchoolCount schoolCount={schools.length} />
    </>
  );
};

export default SchoolsOverview;
