import { Button } from '@mui/material';
import { Undo as BackIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const HomeButton = () => (
  <Button
    variant="contained"
    startIcon={<BackIcon />}
    disableElevation
    component={Link}
    to="/"
  >
    Back to homepage
  </Button>
);

export default HomeButton;
