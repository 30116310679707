import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';

const SchoolStatCardTitle = styled('h4')({
  marginBottom: 12,
  color: grey[500],
  fontSize: 14,
  fontWeight: 400,
});

export default SchoolStatCardTitle;
