import styled from '@emotion/styled';
import { IconButton, IconButtonProps } from '@mui/material';
import React, { useRef, useState } from 'react';
import stripProps from '@/lib/stripProps';
import IconActionButton from './IconActionButton';

// 500KB
const FILE_SIZE_LIMIT = 500_000;

type UploadIconButtonProps = IconButtonProps & {
  onUpload: (file: File) => void;
  iconPreviewVisible?: boolean;
};

const StyledInput = styled('input')({
  display: 'none',
});

type StyledIconButtonProps = Omit<UploadIconButtonProps, 'onUpload'> & {
  fileRejected: boolean;
};

const StyledIconButton = styled(stripProps(IconButton, 'fileRejected'))<
  StyledIconButtonProps
>(({ theme, fileRejected }) => ({
  transition: theme.transitions.create(['border-color']),
  border: `2px solid transparent`,
  borderColor: fileRejected ? theme.palette.error.main : undefined,
}));

const StyledIconActionButton = StyledIconButton.withComponent(
  stripProps(IconActionButton, 'fileRejected'),
);

const UploadIconButton = ({
  onUpload,
  iconPreviewVisible,
  ...props
}: UploadIconButtonProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileRejected, setFileRejected] = useState(false);

  const openFileSelector = () => {
    if (inputRef.current === null) {
      return;
    }

    inputRef.current.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setFileRejected(false);

    const file = e.target.files[0];

    if (file.size > FILE_SIZE_LIMIT) {
      setFileRejected(true);

      return;
    }

    onUpload(file);
  };

  const IconButtonComponent = iconPreviewVisible
    ? StyledIconActionButton
    : StyledIconButton;

  return (
    <>
      <StyledInput
        type="file"
        accept="image/svg+xml, image/jpg, image/jpeg, image/png"
        onChange={handleFileChange}
        ref={inputRef}
      />
      <IconButtonComponent
        fileRejected={fileRejected}
        onClick={openFileSelector}
        {...props}
      />
    </>
  );
};

export default UploadIconButton;
