import { useState } from 'react';
import { AllowedEmail } from '@/routes/schools/types';

// TODO rework typings (see egodact-app)

export type AllowedEmailsWithId = { id: string; allowedEmail: AllowedEmail }[];

const arrayToObject = <T>(array: T[]): Record<string, T> => {
  return Object.fromEntries(Object.entries(array));
};

const useAllowedEmailsState = (initialAllowedEmails: AllowedEmail[]) => {
  const [allowedEmails, setAllowedEmails] = useState(
    arrayToObject(initialAllowedEmails ?? []),
  );

  const allowedEmailsArray: AllowedEmailsWithId = Object.entries(
    allowedEmails,
  ).map(([id, allowedEmail]) => ({
    id,
    allowedEmail,
  }));

  const addAllowedEmail = (newAllowedEmail: AllowedEmail): void => {
    const allowedEmailIds = Object.keys(allowedEmails);
    // TODO add sortByValue
    const highestId =
      allowedEmailIds
        .map(Number)
        .sort((a, b) => a - b)
        .reverse()[0] ?? -1;
    const newAllowedEmailId = highestId + 1;

    setAllowedEmails({
      ...allowedEmails,
      [newAllowedEmailId]: newAllowedEmail,
    });
  };

  const editAllowedEmail = (
    id: string,
    updatedAllowedEmail: AllowedEmail,
  ): void => {
    setAllowedEmails({
      ...allowedEmails,
      [id]: updatedAllowedEmail,
    });
  };

  const removeAllowedEmail = (id: string): void => {
    const newAllowedEmails = { ...allowedEmails };
    delete newAllowedEmails[id];

    const newAllowedEmailsWithoutIdGaps = arrayToObject(
      Object.values(newAllowedEmails),
    );

    setAllowedEmails(newAllowedEmailsWithoutIdGaps);
  };

  return {
    allowedEmails: allowedEmailsArray,
    allowedEmailsAsObject: allowedEmails,
    addAllowedEmail,
    editAllowedEmail,
    removeAllowedEmail,
  };
};

export default useAllowedEmailsState;
