import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { useContext } from 'react';
import AuthenticationStateContext from '@/components/AuthenticationState/AuthenticationStateContext';
import api from '@/lib/api';

const SignoutMenuItem = () => {
  const [, refreshAuthenticationState] = useContext(AuthenticationStateContext);

  const handleSignout = () => {
    api.unauthenticate();

    refreshAuthenticationState();
  };

  return (
    <MenuItem onClick={handleSignout}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText>Sign out</ListItemText>
    </MenuItem>
  );
};

export default SignoutMenuItem;
