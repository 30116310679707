import { SchoolWithStats } from '../../types';
import useSyncedState from '@/lib/useSyncedState';
import { TextField } from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import Space from '@/components/Space';
import api from '@/lib/api';
import EditorCard from './EditorCard';
import useSwrMutate from '@/lib/useSwrMutate';
import EditorTitle from './EditorTitle';

type SchoolDisplayNameEditorProps = {
  school: SchoolWithStats;
};

const SchoolDisplayNameEditor = ({ school }: SchoolDisplayNameEditorProps) => {
  const mutateSchool = useSwrMutate<SchoolWithStats>(`/schools/${school.id}`);

  const [displayName, setDisplayName] = useSyncedState(school.displayName);
  const [saving, setSaving] = useState(false);

  const displayNameChanged = school.displayName !== displayName;

  const handleSave = async () => {
    setSaving(true);

    const updatedSchool = await api.put(`/schools/${school.id}/display-name`, {
      displayName,
    });

    mutateSchool(school => ({ ...school, ...updatedSchool }));

    setSaving(false);
  };

  return (
    <EditorCard>
      <EditorTitle>Display name:</EditorTitle>
      <TextField
        variant="outlined"
        name="display_name"
        value={displayName}
        onChange={e => setDisplayName(e.target.value)}
        size="small"
      />
      <Space width="auto" />
      <LoadingButton
        variant="contained"
        loading={saving}
        disabled={!displayNameChanged || displayName.trim().length === 0}
        startIcon={<SaveIcon />}
        onClick={handleSave}
        loadingPosition="start"
        disableElevation
      >
        Save
      </LoadingButton>
    </EditorCard>
  );
};

export default SchoolDisplayNameEditor;
