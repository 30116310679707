import useSWR, { KeyedMutator, SWRConfiguration, SWRResponse } from 'swr';
import api from './api';

// TODO default to unknown!
const useSwr = <Data = any, Error = any>(
  key: string,
  options?: SWRConfiguration,
): SWRResponse<Data, Error> => {
  const { mutate, ...swrResponse } = useSWR(key, api.call, options);

  const customMutate: KeyedMutator<Data> = (data, options) => {
    if (typeof options === 'boolean') {
      return mutate(data, options);
    }

    return mutate(data, {
      revalidate: false,
      ...options,
    });
  };

  return { mutate: customMutate, ...swrResponse };
};

export default useSwr;
