import React, { useContext } from 'react';
import ConfigGroupTitle from './ConfigGroupTitle';
import InstanceConfigContext from './InstanceConfigContext';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { IdentityConnectionProvider } from '@/routes/schools/types';
import Space from '@/components/Space';

const AuthProviderSelect = () => {
  const [instanceConfig, setInstanceConfig] = useContext(InstanceConfigContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInstanceConfig(instanceConfig => ({
      ...instanceConfig,
      authProvider: e.target.value as IdentityConnectionProvider,
    }));
  };

  return (
    <>
      <ConfigGroupTitle>Authentication provider</ConfigGroupTitle>
      <FormControl>
        <RadioGroup
          name="auth_provider"
          value={instanceConfig!.authProvider}
          onChange={handleChange}
        >
          <FormControlLabel
            label="Google"
            value={IdentityConnectionProvider.GOOGLE}
            control={<Radio />}
          />
          <Space height={-12} />
          <FormControlLabel
            label="Microsoft"
            value={IdentityConnectionProvider.MICROSOFT}
            control={<Radio />}
          />
        </RadioGroup>
        <Space height={-12} />
      </FormControl>
    </>
  );
};

export default AuthProviderSelect;
