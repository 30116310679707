import { PerspectiveCohort } from '../../types';
import UnpublishPerspectiveCohort from './UnpublishPerspectiveCohort';
import ManageScheduledPerspectiveCohortPublishing from './ManageScheduledPerspectiveCohortPublishing';
import PublishPerspectiveCohort from './PublishPerspectiveCohort';

type PerspectiveCohortPublishingProps = {
  perspectiveCohort: PerspectiveCohort;
};

const PerspectiveCohortPublishing = ({
  perspectiveCohort,
}: PerspectiveCohortPublishingProps) => {
  if (perspectiveCohort.publishAt === null) {
    return <PublishPerspectiveCohort perspectiveCohort={perspectiveCohort} />;
  }

  if (new Date(perspectiveCohort.publishAt) >= new Date()) {
    return (
      <ManageScheduledPerspectiveCohortPublishing
        perspectiveCohort={perspectiveCohort}
      />
    );
  }

  return <UnpublishPerspectiveCohort perspectiveCohort={perspectiveCohort} />;
};

export default PerspectiveCohortPublishing;
