import styled from '@emotion/styled';

const PerspectiveCardContent = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: '20px 12px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  '.MuiIconButton-root.visible-on-hover': {
    transition: theme.transitions.create('all'),
    padding: 0,
    width: 0,
    opacity: 0,
  },
  ':hover .MuiIconButton-root.visible-on-hover, .MuiIconButton-root.visible-on-hover:focus': {
    padding: 5,
    width: 34,
    opacity: 1,
    ':first-of-type': {
      marginLeft: 5,
    },
  },
  '.perspective-card-content__open-sublevels-button': {
    width: 0,
    opacity: 0,
  },
  ':hover .perspective-card-content__open-sublevels-button, .perspective-card-content__open-sublevels-button:focus': {
    width: 36,
    opacity: 1,
  },
}));

export default PerspectiveCardContent;
