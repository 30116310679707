import React from 'react';
import useAuthenticatedSuperUser from './AuthenticationState/useAuthenticatedSuperUser';
import { Navigate } from 'react-router-dom';

export type UnauthenticatedOnlyProps = {
  children: React.ReactNode;
};

const UnauthenticatedOnly = ({ children }: UnauthenticatedOnlyProps) => {
  const authenticatedSuperUser = useAuthenticatedSuperUser();

  if (authenticatedSuperUser !== null) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default UnauthenticatedOnly;
