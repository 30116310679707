import React from 'react';
import useAuthenticatedSuperUser from './AuthenticationState/useAuthenticatedSuperUser';
import { Navigate } from 'react-router-dom';

export type RequireAuthenticationProps = {
  children: React.ReactNode;
};

const RequireAuthentication = ({ children }: RequireAuthenticationProps) => {
  const authenticatedSuperUser = useAuthenticatedSuperUser();

  if (authenticatedSuperUser === null) {
    return <Navigate to="/auth/login" replace />;
  }

  return children;
};

export default RequireAuthentication;
