import {
  PerspectiveCohort,
  PerspectiveCohortWithPerspectives,
} from '../../types';
import { Publish as PublishIcon } from '@mui/icons-material';
import Dialog from '@/components/Dialog';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import DateCalendarWrapper from './DateCalendarWrapper';
import api from '@/lib/api';
import useSwrMutate from '@/lib/useSwrMutate';
import ConfirmPublicationCheckbox from './ConfirmPublicationCheckbox';

type PublishPerspectiveCohortProps = {
  perspectiveCohort: PerspectiveCohort;
};

const PublishPerspectiveCohort = ({
  perspectiveCohort,
}: PublishPerspectiveCohortProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [publishAt, setPublishAt] = useState(moment());
  const [actionConfirmed, setActionConfirmed] = useState(false);

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setActionConfirmed(false);
  }, [dialogOpen, publishAt]);

  const mutate = useSwrMutate<PerspectiveCohortWithPerspectives>(
    `/perspectives/cohorts/${perspectiveCohort.id}`,
  );

  const handlePublish = async () => {
    setDialogOpen(false);

    setSaving(true);

    await api.post(`/perspectives/cohorts/${perspectiveCohort.id}/publish`, {
      publishAt: publishAt.toDate(),
    });

    mutate(perspectiveCohort => {
      if (typeof perspectiveCohort === 'undefined') {
        return;
      }

      return {
        ...perspectiveCohort,
        publishAt: publishAt.toDate().toISOString(),
      };
    });

    setSaving(false);
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        startIcon={<PublishIcon />}
        loadingPosition="start"
        onClick={() => setDialogOpen(true)}
        loading={saving}
        disableElevation
      >
        Publish
      </LoadingButton>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Dialog.Title>Publish {perspectiveCohort.name}</Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            Select a publication date for this perspective cohort. Users that
            join Egodact after this publication date will automatically be
            assigned to this perspective cohort.
          </Dialog.ContentText>
        </Dialog.Content>
        <DateCalendarWrapper>
          <DateCalendar
            value={publishAt}
            onChange={value => setPublishAt(value ?? moment())}
          />
        </DateCalendarWrapper>
        <Dialog.Content>
          <ConfirmPublicationCheckbox
            publishAt={publishAt}
            checked={actionConfirmed}
            onChange={(_, checked: boolean) => setActionConfirmed(checked)}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton onClick={() => setDialogOpen(false)} />
          <Dialog.Actions.OkButton
            color="warning"
            onClick={handlePublish}
            disabled={!actionConfirmed}
          >
            Publish
          </Dialog.Actions.OkButton>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default PublishPerspectiveCohort;
