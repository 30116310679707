import PerspectiveCard from '../PerspectiveCard';
import useRefreshPerspectives from '../useRefreshPerspectives';
import { AddCircle as AddIcon } from '@mui/icons-material';
import AddButton from './AddButton';
import React, { useContext, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import Space from '@/components/Space';
import PushToRight from '@/components/PushToRight';
import api from '@/lib/api';
import PerspectiveCohortContext from '../../PerspectiveCohortContext';
import PerspectiveCardContent from '../PerspectiveCard/PerspectiveCardContent';
import TextFieldWithEscListener from '@/components/TextFieldWithEscListener';

const AddPerspective = () => {
  const perspectiveCohort = useContext(PerspectiveCohortContext);
  const refreshPerspectives = useRefreshPerspectives();

  const [name, setName] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);

  const [saving, setSaving] = useState(false);

  const handleAdd = async (e: React.FormEvent) => {
    e.preventDefault();

    setSaving(true);

    await api.post(`/perspectives?cohortId=${perspectiveCohort.id}`, {
      name,
    });

    await refreshPerspectives();

    setSaving(false);
    setShowAddForm(false);
    setName('');
  };

  return (
    <PerspectiveCard>
      {showAddForm ? (
        <PerspectiveCardContent>
          <form onSubmit={handleAdd}>
            <TextFieldWithEscListener
              label="Name"
              size="small"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
              onEscPress={() => setShowAddForm(false)}
              fullWidth
              autoFocus
            />
            <Space height={12} />
            <PushToRight>
              <LoadingButton
                variant="contained"
                size="small"
                type="submit"
                loading={saving}
                disabled={name.trim().length === 0}
                disableElevation
              >
                Add
              </LoadingButton>
            </PushToRight>
          </form>
        </PerspectiveCardContent>
      ) : (
        <AddButton onClick={() => setShowAddForm(true)}>
          <AddIcon />
          <h4>New perspective</h4>
        </AddButton>
      )}
    </PerspectiveCard>
  );
};

export default AddPerspective;
