import useSyncedState from '@/lib/useSyncedState';
import { Perspective } from '@/routes/perspectives/types';
import styled from '@emotion/styled';
import TextFieldWithEscListener from '@/components/TextFieldWithEscListener';
import { IconButton } from '@mui/material';
import { Check as SaveIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import useRefreshPerspectives from '../useRefreshPerspectives';
import api from '@/lib/api';
import Space from '@/components/Space';

type PerspectiveNameEditorProps = {
  perspective: Perspective;
  onClose: () => void;
};

const StyledForm = styled('form')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const PerspectiveNameEditor = ({
  perspective,
  onClose,
}: PerspectiveNameEditorProps) => {
  const refreshPerspectives = useRefreshPerspectives();

  const [name, setName] = useSyncedState(perspective.name);
  const [saving, setSaving] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setSaving(true);

    await api.put(`/perspectives/${perspective.id}/name`, {
      name,
    });
    await refreshPerspectives();

    setSaving(false);
    onClose();
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <TextFieldWithEscListener
        variant="outlined"
        placeholder="name"
        value={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setName(e.target.value)
        }
        size="small"
        autoFocus
        onEscPress={onClose}
      />
      <Space width={8} />
      <IconButton
        size="small"
        disabled={name.trim().length === 0 || saving}
        type="submit"
      >
        <SaveIcon />
      </IconButton>
    </StyledForm>
  );
};

export default PerspectiveNameEditor;
