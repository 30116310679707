import AppTitle from '@/components/AppTitle';
import MainPageTitle from '@/components/MainPageTitle';
import Card from '@/components/Card';
import React, { useContext, useState } from 'react';
import AuthenticationStateContext from '@/components/AuthenticationState/AuthenticationStateContext';
import ApiError from '@/lib/api/ApiError';
import useSyncedState from '@/lib/useSyncedState';
import api from '@/lib/api';
import Space from '@/components/Space';
import PushToRight from '@/components/PushToRight';
import { LoadingButton } from '@mui/lab';
import FlexWrapper from '@/components/FlexWrapper';
import SaveErrorAlert from './SaveErrorAlert';
import ProfileSettingLabel from './ProfileSettingLabel';

type ProfileErrors = {
  name: string | null;
  firstName: string | null;
};

const ProfileSettings = () => {
  const [authenticatedUser, refreshAuthenticationState] = useContext(
    AuthenticationStateContext,
  );

  const [name, setName] = useSyncedState(
    authenticatedUser?.profile?.name ?? '',
  );
  const [firstName, setFirstName] = useSyncedState(
    authenticatedUser?.profile?.firstName ?? '',
  );

  const [profileErrors, setProfileErrors] = useState<ProfileErrors>({
    name: null,
    firstName: null,
  });

  const [saving, setSaving] = useState(false);
  const [genericSaveError, setGenericSaveError] = useState<string | null>(null);

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();

    setGenericSaveError(null);

    const profileErrors = {
      name: name.trim().length === 0 ? 'Please enter your name' : null,
      firstName:
        firstName.trim().length === 0 ? 'Please enter your first name' : null,
    };

    setProfileErrors(profileErrors);

    if (profileErrors.name !== null || profileErrors.firstName !== null) {
      return;
    }

    setSaving(true);

    try {
      await api.put('profile', {
        name,
        firstName,
      });

      refreshAuthenticationState();
    } catch (error) {
      if (!(error instanceof ApiError)) {
        throw error;
      }

      setGenericSaveError(error.getErrorCode());
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <AppTitle>Your profile</AppTitle>
      <MainPageTitle>Your profile</MainPageTitle>
      <Card>
        {genericSaveError !== null && (
          <SaveErrorAlert errorCode={genericSaveError} />
        )}
        <Card.Title>Your personal information</Card.Title>
        <Space height={24} />
        <form onSubmit={handleSave}>
          <FlexWrapper>
            <ProfileSettingLabel htmlFor="name-field">
              Full name:
            </ProfileSettingLabel>
            <Card.TextField
              id="name-field"
              placeholder="Full name"
              name="name"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
              error={profileErrors.name !== null}
              helperText={profileErrors.name}
              disabled={saving}
              width={300}
            />
          </FlexWrapper>
          <Space height={24} />
          <FlexWrapper>
            <ProfileSettingLabel htmlFor="first-name-field">
              First name:
            </ProfileSettingLabel>
            <Card.TextField
              id="first-name-field"
              placeholder="First name"
              name="first_name"
              value={firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFirstName(e.target.value)
              }
              error={profileErrors.firstName !== null}
              helperText={profileErrors.firstName}
              disabled={saving}
              width={300}
            />
          </FlexWrapper>
          <Space height={12} />
          <PushToRight>
            <LoadingButton
              variant="contained"
              loading={saving}
              type="submit"
              disableElevation
            >
              Save
            </LoadingButton>
          </PushToRight>
        </form>
      </Card>
    </>
  );
};

export default ProfileSettings;
