import styled from '@emotion/styled';

type PerspectiveCardHeaderProps = {
  fullSize?: boolean;
};

const PerspectiveCardHeader = styled('header')<PerspectiveCardHeaderProps>(
  ({ fullSize }) => ({
    height: fullSize ? '90%' : 24,
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

export default PerspectiveCardHeader;
