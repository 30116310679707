import ApiAuthLocalStorage from './ApiAuthLocalStorage';
import ApiAuthenticationError from './ApiAuthenticationError';
import ApiError from './ApiError';
import buildApiUrl from './buildApiUrl';

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

// TODO use unknown? if possible? and add generics for expected responses
export type ResponseBody = any | null;

type AuthenticationStateListener = () => void;

class Api {
  private authenticationStateListeners: AuthenticationStateListener[] = [];

  constructor() {
    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
    this.call = this.call.bind(this);
  }

  isAuthenticated(): boolean {
    const accessToken = ApiAuthLocalStorage.getAccessToken();

    return accessToken !== null;
  }

  authenticate(accessToken: string): void {
    ApiAuthLocalStorage.saveAccessToken(accessToken);

    this.triggerAuthenticationStateListeners();
  }

  unauthenticate(): void {
    ApiAuthLocalStorage.clearAccessToken();

    this.triggerAuthenticationStateListeners();
  }

  addAuthenticationStateListener(listener: AuthenticationStateListener): void {
    this.authenticationStateListeners.push(listener);
  }

  removeAuthenticationStateListener(
    listener: AuthenticationStateListener,
  ): void {
    if (!this.authenticationStateListeners.includes(listener)) {
      return;
    }

    this.authenticationStateListeners.splice(
      this.authenticationStateListeners.indexOf(listener),
      1,
    );
  }

  get(url: string, options: RequestInit = {}): Promise<ResponseBody> {
    return this.performRequestForMethod(url, HttpMethod.GET, options);
  }

  post(
    url: string,
    body: Record<string, unknown> = {},
    options: RequestInit = {},
  ): Promise<ResponseBody> {
    return this.performRequestWithBody(url, HttpMethod.POST, body, options);
  }

  put(
    url: string,
    body: Record<string, unknown> = {},
    options: RequestInit = {},
  ): Promise<ResponseBody> {
    return this.performRequestWithBody(url, HttpMethod.PUT, body, options);
  }

  delete(
    url: string,
    body: Record<string, unknown> = {},
    options: RequestInit = {},
  ): Promise<ResponseBody> {
    return this.performRequestWithBody(url, HttpMethod.DELETE, body, options);
  }

  call(url: string, options: RequestInit = {}): Promise<ResponseBody> {
    return this.performRequest(url, options);
  }

  private async performRequest(
    url: string,
    options: RequestInit,
  ): Promise<ResponseBody> {
    const additionalHeaders: HeadersInit = {};

    const accessToken = ApiAuthLocalStorage.getAccessToken();

    if (accessToken !== null) {
      additionalHeaders['Authorization'] = `Bearer ${accessToken}`;
    }

    if (options.body && typeof options.body === 'string') {
      additionalHeaders['Content-Type'] = 'application/json';
    }

    const response = await fetch(buildApiUrl(url), {
      ...options,
      headers: {
        ...additionalHeaders,
        ...options.headers,
      },
    });

    if (response.status === 204) {
      return null;
    }

    const responseContent: ResponseBody = await this.getResponseContent(
      response,
    );

    if (response.status === 401) {
      api.unauthenticate();

      throw new ApiAuthenticationError(responseContent);
    }

    if (![200, 201, 204].includes(response.status)) {
      throw new ApiError(responseContent);
    }

    return responseContent;
  }

  private async performRequestForMethod(
    url: string,
    method: HttpMethod,
    options: RequestInit,
  ): Promise<ResponseBody> {
    return this.performRequest(url, {
      ...options,
      method,
    });
  }

  private performRequestWithBody(
    url: string,
    method: HttpMethod,
    body: Record<string, unknown>,
    options: RequestInit,
  ): Promise<ResponseBody> {
    return this.performRequestForMethod(url, method, {
      ...options,
      body: JSON.stringify(body),
    });
  }

  private getResponseContent(response: Response): Promise<ResponseBody> {
    const responseContentType = response.headers.get('Content-Type');

    if (
      responseContentType === null ||
      !responseContentType.includes('application/json')
    ) {
      return response.blob();
    }

    return response.json();
  }

  private triggerAuthenticationStateListeners(): void {
    for (const listener of this.authenticationStateListeners) {
      listener();
    }
  }
}

const api = new Api();

export default api;
