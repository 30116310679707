import { useMemo } from 'react';
import getRandomColor from './getRandomColor';
import * as colors from '@mui/material/colors';

type Shade = keyof typeof colors.blue;

type ShadedColor = string;
type Color = { [shade in Shade]: ShadedColor };

function useRandomColor(): Color;
function useRandomColor(shade: Shade): ShadedColor;
function useRandomColor(shade?: Shade): Color | ShadedColor {
  const randomColor = useMemo(() => getRandomColor(), []);

  if (shade) {
    return randomColor[shade];
  }

  return randomColor;
}

export default useRandomColor;
