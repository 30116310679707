import styled from '@emotion/styled';

const TilesWrapper = styled('div')({
  marginTop: 2,
  marginLeft: -6,
  marginRight: -6,
  display: 'flex',
  width: 'calc(100% + 12px)',
  flexWrap: 'wrap',
});

export default TilesWrapper;
