import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';

const BasicInfoWrapper = styled('div')({
  marginLeft: 18,
  marginRight: 'auto',
  lineHeight: 1,
  h3: {
    marginBottom: 4,
    fontSize: 16,
  },
  em: {
    color: grey[600],
    fontSize: 14,
    fontStyle: 'normal',
  },
});

export default BasicInfoWrapper;
