import ResponsiveDialog from './ResponsiveDialog';
import { DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import DialogActions from './DialogActions';
import { DialogProps as MuiDialogProps } from '@mui/material';

type DialogProps = MuiDialogProps & {
  open: boolean;
  dangerous?: boolean;
};

const Dialog = ({ open, ...props }: DialogProps) => (
  <ResponsiveDialog open={open} {...props} />
);

Dialog.Title = DialogTitle;
Dialog.Content = DialogContent;
Dialog.ContentText = DialogContentText;
Dialog.Actions = DialogActions;

export default Dialog;
