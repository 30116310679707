import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';
import { ChevronRight as ChevronIcon } from '@mui/icons-material';
import EgodactOnly from '@/components/EgodactOnly';

const StyledShortcutTile = styled(Paper)({
  margin: 6,
  minWidth: 'calc(50% - 12px)',
  maxWidth: 'calc(50% - 12px)',
  display: 'inline-flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
});

type ShortcutTileProps = {
  egodactOnly?: boolean;
  children: React.ReactNode;
};

const ShortcutTile = ({ egodactOnly, children }: ShortcutTileProps) => (
  <EgodactOnly active={egodactOnly}>
    <StyledShortcutTile variant="outlined">{children}</StyledShortcutTile>
  </EgodactOnly>
);

const StyledShortcutTileContent = styled('div')(({ theme }) => ({
  padding: 24,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  '.MuiSvgIcon-root': {
    marginLeft: 24,
    padding: 12,
    width: 32,
    height: 32,
    backgroundColor: theme.palette.action.selected,
    borderRadius: '25%',
  },
}));

type ShortcutTileContentProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
};

const ShortcutTileContent = ({ icon, children }: ShortcutTileContentProps) => (
  <StyledShortcutTileContent>
    <div>{children}</div>
    {icon}
  </StyledShortcutTileContent>
);

const ShortcutTileTitle = styled('h2')({
  marginBottom: 12,
});

const ShortcutTileContentText = styled('p')({
  marginBottom: 0,
  color: 'rgba(0, 0, 0, .7)',
});

const StyledShortcutTileLink = styled(Link)(({ theme }) => ({
  padding: '12px 24px',
  color: theme.palette.primary.main,
  fontWeight: 500,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create('background-color'),
  ':hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

type ShortcutTileLinkProps = {
  to: string;
  children: React.ReactNode;
};

const ShortcutTileLink = ({ children, ...props }: ShortcutTileLinkProps) => (
  <StyledShortcutTileLink {...props}>
    {children}
    <ChevronIcon />
  </StyledShortcutTileLink>
);

ShortcutTile.Content = ShortcutTileContent;
ShortcutTile.Title = ShortcutTileTitle;
ShortcutTile.ContentText = ShortcutTileContentText;
ShortcutTile.Link = ShortcutTileLink;

export default ShortcutTile;
