import styled from '@emotion/styled';

const MainPageTitle = styled('h1')({
  marginBottom: 24,
  fontSize: 28,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export default MainPageTitle;
