import styled from '@emotion/styled';
import HigherCardConnector from './HigherCardConnector';
import StraightCardConnector from './StraightCardConnector';
import LowerCardConnector from './LowerCardConnector';
import { CONNECTOR_WRAPPER_WIDTH } from './styleConstants';

type CardConnectorsProps = {
  cardCount: number;
  parentCardIndex: number;
  highlightedConnector: number | null;
};

const CardConnectorsWrapper = styled('div')({
  position: 'relative',
  width: CONNECTOR_WRAPPER_WIDTH,
  minWidth: CONNECTOR_WRAPPER_WIDTH,
  maxWidth: CONNECTOR_WRAPPER_WIDTH,
});

const CardConnectors = ({
  cardCount,
  parentCardIndex,
  highlightedConnector,
}: CardConnectorsProps) => (
  <CardConnectorsWrapper>
    {new Array(cardCount).fill(null).map((_, index) => {
      if (index < parentCardIndex) {
        return (
          <HigherCardConnector
            index={index}
            parentCardIndex={parentCardIndex}
            highlighted={
              highlightedConnector === null || highlightedConnector === index
            }
            key={index}
          />
        );
      }

      if (index === parentCardIndex) {
        return (
          <StraightCardConnector
            index={parentCardIndex}
            highlighted={
              highlightedConnector === null || highlightedConnector === index
            }
            key={index}
          />
        );
      }

      return (
        <LowerCardConnector
          index={index}
          parentCardIndex={parentCardIndex}
          highlighted={
            highlightedConnector === null || highlightedConnector === index
          }
          key={index}
        />
      );
    })}
  </CardConnectorsWrapper>
);

export default CardConnectors;
