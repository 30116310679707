import { useContext } from 'react';
import { useSWRConfig } from 'swr';
import PerspectiveCohortContext from '../PerspectiveCohortContext';

const useRefreshPerspectives = () => {
  const perspectiveCohort = useContext(PerspectiveCohortContext);
  const { mutate } = useSWRConfig();

  const refreshPerspectives = () => {
    return mutate(`/perspectives/cohorts/${perspectiveCohort.id}`);
  };

  return refreshPerspectives;
};

export default useRefreshPerspectives;
