import DialogButton from './DialogButton';
import { ButtonProps } from '@mui/material';

const DialogCreateButton = ({ children, ...props }: ButtonProps) => (
  <DialogButton color="primary" {...props}>
    {children ?? 'Create'}
  </DialogButton>
);

export default DialogCreateButton;
