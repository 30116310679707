import { common, grey, red } from '@mui/material/colors';

const logXssWarning = () => {
  console.log(
    '%cStop!',
    `color: ${red[700]}; font-family: Arial; font-size: 48px; font-weight: 700;`,
  );
  console.log(
    "%cDon't copy-paste code here that you don't understand. Doing so might get your account hacked, compromising Egodact's security.",
    `color: ${common.black}; font-family: Arial; font-size: 16px;`,
  );
  console.log(
    '%cFound an issue? bugs@egodact.com',
    `color: ${grey[700]}; font-family: Arial; font-size: 16px;`,
  );
};

export default logXssWarning;
