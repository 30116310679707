/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import AuthenticationStateContext from './components/AuthenticationState/AuthenticationStateContext';
import api from './lib/api';

const useAutoSignOut = () => {
  const [authenticatedSuperUser, refreshAuthenticationState] = useContext(
    AuthenticationStateContext,
  );

  useEffect(() => {
    const autoSignOutListener = () => {
      if (!api.isAuthenticated() && authenticatedSuperUser !== null) {
        refreshAuthenticationState();
      }
    };

    api.addAuthenticationStateListener(autoSignOutListener);

    return () => api.removeAuthenticationStateListener(autoSignOutListener);
  }, [authenticatedSuperUser]);
};

export default useAutoSignOut;
