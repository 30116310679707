import { Perspective } from '@/routes/perspectives/types';
import groupBy from 'lodash.groupby';
import PerspectiveManagerColumn from '../PerspectiveManagerColumn';
import AddPerspectiveSublevel from './AddPerspectiveSublevel';
import IndividualPerspectiveSublevel from './IndividualPerspectiveSublevel';
import useColumns, { Column } from './useColumns';

type PerspectiveSublevelsProps = {
  perspective: Perspective;
  perspectiveIndex: number;
};

const PerspectiveSublevels = ({
  perspective,
  perspectiveIndex,
}: PerspectiveSublevelsProps) => {
  // TODO separate open and close functions, makes for less confusing code
  const [columns, openSublevel] = useColumns(perspective, perspectiveIndex);

  const sublevelsByParentId = groupBy(perspective.sublevels, 'parentId');

  const getSublevelsForParentId = (parentId: number | null) => {
    if (parentId === null) {
      return sublevelsByParentId.null ?? [];
    }

    return sublevelsByParentId[parentId.toString()] ?? [];
  };

  // TODO maybe refactor columns state so that root column is rendered anyway,
  // having a columns state that always includes a [0] = null element is
  // confusing
  return (
    <>
      {columns.map((column, nestingIndex) => {
        const nextColumn = (columns[nestingIndex + 1] as Column | null) ?? null;

        return (
          <PerspectiveManagerColumn
            parentCardIndex={
              column.isRoot
                ? column.perspectiveIndex
                : column.parentSublevelIndex
            }
            highlightedConnector={nextColumn?.parentSublevelIndex ?? null}
            key={nestingIndex}
          >
            {getSublevelsForParentId(
              column.isRoot ? null : column.parentSublevelId,
            ).map((sublevel, index) => (
              <IndividualPerspectiveSublevel
                perspectiveSublevel={sublevel}
                perspective={perspective}
                onOpen={() => openSublevel(sublevel.id, index, nestingIndex)}
                open={nextColumn?.parentSublevelId === sublevel.id}
                faded={
                  nextColumn !== null &&
                  nextColumn.parentSublevelId !== sublevel.id
                }
                key={sublevel.id}
              />
            ))}
            <AddPerspectiveSublevel
              perspective={perspective}
              parentId={column.isRoot ? null : column.parentSublevelId}
            />
          </PerspectiveManagerColumn>
        );
      })}
    </>
  );
};

export default PerspectiveSublevels;
