import styled from '@emotion/styled';
import { Drawer } from '@mui/material';

const StyledDrawer = styled(Drawer)({
  width: 300,
  '.MuiPaper-root': {
    position: 'fixed',
    width: 300,
  },
});

export default StyledDrawer;
