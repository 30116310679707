import LoadingSpinner from '@/components/LoadingSpinner';
import MainPageTitle from '@/components/MainPageTitle';
import { Alert } from '@mui/material';
import { useParams } from 'react-router-dom';
import { SchoolWithStats } from '../types';
import SchoolStats from './SchoolStats';
import SchoolDataMigrations from './SchoolDataMigrations';
import SchoolDisplayNameEditor from './SchoolDisplayNameEditor';
import InstanceConfigManager from './InstanceConfigManager';
import useSwr from '@/lib/useSwr';
import AppTitle from '@/components/AppTitle';
import AccessSchoolInstanceButton from '../components/AccessSchoolInstanceButton';

type UpdateSchoolParams = {
  id: string;
};

const UpdateSchool = () => {
  const { id } = useParams() as UpdateSchoolParams;
  const { isLoading, data: school, error } = useSwr<SchoolWithStats>(
    `/schools/${id}`,
  );

  const pageTitle = isLoading || !school ? 'Manage school' : school.displayName;

  return (
    <>
      <AppTitle>{pageTitle}</AppTitle>
      <MainPageTitle>
        {pageTitle}
        {!isLoading && school && (
          <AccessSchoolInstanceButton schoolReference={school.reference} />
        )}
      </MainPageTitle>
      {isLoading && <LoadingSpinner margin={48} centered />}
      {!isLoading && (error || typeof school === 'undefined') && (
        <Alert severity="error">Failed to load school.</Alert>
      )}
      {!isLoading && school && (
        <>
          <SchoolStats school={school} />
          <SchoolDisplayNameEditor school={school} />
          <InstanceConfigManager school={school} />
          <SchoolDataMigrations school={school} />
        </>
      )}
    </>
  );
};

export default UpdateSchool;
