import Card from '@/components/Card';
import { SchoolWithStats } from '../types';
import styled from '@emotion/styled';
import DataMigrationStatusIndicator from '@/routes/data-migrations/DataMigrationsManager/DataMigrationsOverview/DataMigrationStatusIndicator';

type SchoolDataMigrationsProps = {
  school: SchoolWithStats;
};

const NoDataMigrationsText = styled('p')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const DataMigrationsWrapper = styled('ul')({
  margin: 0,
  padding: 0,
  listStyle: 'none',
});

const DataMigration = styled('li')(({ theme }) => ({
  padding: '8px 0',
  display: 'flex',
  alignItems: 'center',
  borderBottom: `2px solid ${theme.palette.divider}`,
  ':last-child': {
    borderBottom: 'none',
  },
  h4: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  span: {
    marginRight: 0,
  },
}));

const SchoolDataMigrations = ({ school }: SchoolDataMigrationsProps) => (
  <Card>
    <Card.Title>Data migrations</Card.Title>
    {school.dataMigrations.length === 0 ? (
      <NoDataMigrationsText>
        No data migrations have been applied to this school
      </NoDataMigrationsText>
    ) : (
      <DataMigrationsWrapper>
        {school.dataMigrations.map(dataMigration => (
          <DataMigration key={dataMigration.id}>
            <h4>Data migration #{dataMigration.id}</h4>
            <DataMigrationStatusIndicator dataMigration={dataMigration} />
          </DataMigration>
        ))}
      </DataMigrationsWrapper>
    )}
  </Card>
);

export default SchoolDataMigrations;
