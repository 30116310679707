import validator from 'validator';

const validateUrl = (value: string): string | null => {
  if (value.trim().length === 0) {
    return 'Please enter a URL';
  }

  if (!validator.isURL(value)) {
    return 'Please enter a valid URL';
  }

  return null;
};

export default validateUrl;
