import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)({
  color: grey[600],
  ':hover': {
    textDecoration: 'underline',
  },
});

const ForgotPasswordLink = () => (
  <StyledLink to="/auth/forgot-password">I forgot my password</StyledLink>
);

export default ForgotPasswordLink;
