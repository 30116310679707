import { useState } from 'react';
import SecondaryIconActionButton from '../components/SecondaryIconActionButton';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Dialog from '@/components/Dialog';
import { Perspective } from '@/routes/perspectives/types';

type DeletePerspectiveIconProps = {
  perspective: Perspective;
  onDelete: () => void;
};

const DeletePerspectiveIcon = ({
  perspective,
  onDelete,
}: DeletePerspectiveIconProps) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  return (
    <>
      <SecondaryIconActionButton
        color="error"
        onClick={() => setConfirmationDialogOpen(true)}
      >
        <DeleteIcon />
      </SecondaryIconActionButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>Are you sure?</Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            Are you sure you want to delete the icon attached to{' '}
            <strong>{perspective.name}</strong>? You can upload a new icon
            later.
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={onDelete} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeletePerspectiveIcon;
