import { SchoolDataMigration } from '@/routes/data-migrations/types';
import { LoadingButton } from '@mui/lab';
import { Refresh as RetryIcon } from '@mui/icons-material';
import { useState } from 'react';
import api from '@/lib/api';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import Space from '@/components/Space';

type RetryDataMigrationProps = {
  dataMigration: SchoolDataMigration;
};

const RetryFormWrapper = styled('form')(({ theme }) => ({
  marginTop: 24,
  color: theme.palette.text.secondary,
  display: 'flex',
  alignItems: 'center',
}));

const RetryDataMigration = ({ dataMigration }: RetryDataMigrationProps) => {
  const [legacyDatabaseSecret, setLegacyDatabaseSecret] = useState('');
  const [legacyDatabaseSecretError, setLegacyDatabaseSecretError] = useState<
    string | null
  >(null);

  const [loading, setLoading] = useState(false);
  const [hasAttemptedRetry, setHasAttemptedRetry] = useState(false);

  const handleRetry = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    setLegacyDatabaseSecretError(null);

    if (legacyDatabaseSecret.trim().length === 0) {
      setLegacyDatabaseSecretError('Please enter a database secret');

      return;
    }

    setLoading(true);

    await api.post(`/data-migrations/${dataMigration.id}/retry`, {
      legacyDatabaseSecret,
    });

    setLoading(false);
    setHasAttemptedRetry(true);
  };

  if (hasAttemptedRetry) {
    return null;
  }

  return (
    <RetryFormWrapper onSubmit={handleRetry}>
      <TextField
        label="Legacy database secret"
        name="legacy_database_secret"
        value={legacyDatabaseSecret}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setLegacyDatabaseSecret(e.target.value)
        }
        type="password"
        error={legacyDatabaseSecretError !== null}
        helperText={legacyDatabaseSecretError}
        size="small"
        disabled={loading}
      />
      <Space width={12} />
      <LoadingButton
        variant="contained"
        startIcon={<RetryIcon />}
        loadingPosition="start"
        type="submit"
        loading={loading}
        disableElevation
      >
        Retry
      </LoadingButton>
    </RetryFormWrapper>
  );
};

export default RetryDataMigration;
