import AppTitle from '@/components/AppTitle';
import AppWrapper from '@/components/AppWrapper';
import MainPageTitle from '@/components/MainPageTitle';
import HomeButton from './NotFound/HomeButton';
import { Error as ErrorIcon } from '@mui/icons-material';
import Space from '@/components/Space';

const ErrorScreen = () => (
  <>
    <AppTitle>Something went wrong</AppTitle>
    <AppWrapper>
      <MainPageTitle>
        <ErrorIcon color="error" fontSize="large" />
        <Space width={12} />
        Something went wrong
        <Space width="auto" />
      </MainPageTitle>
      <p>An unknown error occurred. Egodact has been informed.</p>
      <HomeButton />
    </AppWrapper>
  </>
);

export default ErrorScreen;
