import { SchoolWithStats } from '../../types';
import CardWrapper from './CardWrapper';
import SchoolStatCard from './SchoolStatCard';
import SchoolStatCardTitle from './SchoolStatCardTitle';
import SchoolStatCardValue from './SchoolStatCardValue';

type SchoolStatsProps = {
  school: SchoolWithStats;
};

const SchoolStats = ({ school }: SchoolStatsProps) => (
  <CardWrapper>
    <SchoolStatCard>
      <SchoolStatCardTitle>Users</SchoolStatCardTitle>
      <SchoolStatCardValue>{school._count.users}</SchoolStatCardValue>
    </SchoolStatCard>
    <SchoolStatCard>
      <SchoolStatCardTitle>Challenges</SchoolStatCardTitle>
      <SchoolStatCardValue>{school._count.challenges}</SchoolStatCardValue>
    </SchoolStatCard>
    <SchoolStatCard>
      <SchoolStatCardTitle>Tasks</SchoolStatCardTitle>
      <SchoolStatCardValue>{school._count.tasks}</SchoolStatCardValue>
    </SchoolStatCard>
  </CardWrapper>
);

export default SchoolStats;
