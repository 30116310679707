import styled from '@emotion/styled';
import { AppBar } from '@mui/material';
import { common } from '@mui/material/colors';

const InnerStyledAppBar = styled(AppBar)({
  backgroundColor: common.white,
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
});

const StyledAppBar = (props: Record<string, unknown>) => (
  <InnerStyledAppBar
    position="relative"
    variant="outlined"
    elevation={0}
    color="default"
    {...props}
  />
);

export default StyledAppBar;
