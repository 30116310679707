import {
  red,
  blue,
  purple,
  orange,
  teal,
  grey,
  pink,
  indigo,
  brown,
  deepPurple,
} from '@mui/material/colors';

const colors = [
  red,
  blue,
  purple,
  orange,
  teal,
  grey,
  pink,
  indigo,
  brown,
  deepPurple,
];

// TODO move to useRandomColor? or at least in that dir
const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * colors.length);

  return colors[randomIndex];
};

export default getRandomColor;
