import React from 'react';
import useAuthenticatedSuperUser from './AuthenticationState/useAuthenticatedSuperUser';
import SuperUserRole from '@/lib/constants/SuperUserRole';

type EgodactOnlyProps = {
  active?: boolean;
  children: React.ReactNode;
};

const EgodactOnly = ({ active, children }: EgodactOnlyProps) => {
  const authenticatedSuperUser = useAuthenticatedSuperUser();

  if (!active) {
    return children;
  }

  if (authenticatedSuperUser?.role !== SuperUserRole.EGODACT_USER) {
    return null;
  }

  return children;
};

export default EgodactOnly;
