import {
  PerspectiveCohort,
  PerspectiveCohortWithPerspectives,
} from '../../types';
import { Checkbox, FormControlLabel } from '@mui/material';
import { VisibilityOff as UnpublishIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import Dialog from '@/components/Dialog';
import Underlined from '@/components/Underlined';
import Space from '@/components/Space';
import useSwrMutate from '@/lib/useSwrMutate';
import api from '@/lib/api';

type UnpublishPerspectiveCohortProps = {
  perspectiveCohort: PerspectiveCohort;
};

const UnpublishPerspectiveCohort = ({
  perspectiveCohort,
}: UnpublishPerspectiveCohortProps) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [actionConfirmed, setActionConfirmed] = useState(false);

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setActionConfirmed(false);
  }, [confirmationDialogOpen]);

  const mutate = useSwrMutate<PerspectiveCohortWithPerspectives>(
    `/perspectives/cohorts/${perspectiveCohort.id}`,
  );

  const handleUnpublish = async () => {
    setConfirmationDialogOpen(false);

    setSaving(true);

    await api.post(`/perspectives/cohorts/${perspectiveCohort.id}/unpublish`, {
      publishAt: null,
    });

    mutate(perspectiveCohort => {
      if (typeof perspectiveCohort === 'undefined') {
        return;
      }

      return {
        ...perspectiveCohort,
        publishAt: null,
      };
    });

    setSaving(false);
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        startIcon={<UnpublishIcon />}
        loadingPosition="start"
        color="error"
        onClick={() => setConfirmationDialogOpen(true)}
        loading={saving}
        disableElevation
      >
        Unpublish
      </LoadingButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>Are you sure?</Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            Are you you want to unpublish this perspective cohort? It will
            immediately become unavailable to all Egodact users.
          </Dialog.ContentText>
          <Space height={20} />
          <FormControlLabel
            label={
              <>
                I understand that <strong>{perspectiveCohort.name}</strong> will
                immediately become unavailable for{' '}
                <Underlined>all Egodact users</Underlined>, altering their
                perspective portfolios.
              </>
            }
            control={<Checkbox />}
            checked={actionConfirmed}
            onChange={(_, checked: boolean) => setActionConfirmed(checked)}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton
            onClick={handleUnpublish}
            disabled={!actionConfirmed}
          >
            Unpublish
          </Dialog.Actions.DeleteButton>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default UnpublishPerspectiveCohort;
