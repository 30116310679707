import styled from '@emotion/styled';
import stripProps from '@/lib/stripProps';

type StyledColumnProps = Record<string, unknown> & {
  hidden: boolean;
};

const StyledColumn = styled(stripProps('ul', 'hidden'))<StyledColumnProps>(
  ({ theme, hidden }) => ({
    margin: 0,
    padding: 0,
    listStyle: 'none',
    transition: theme.transitions.create('all'),
    visibility: hidden ? 'hidden' : 'visible',
    opacity: hidden ? 0 : 1,
  }),
);

export default StyledColumn;
