import stripProps from '@/lib/stripProps';
import styled from '@emotion/styled';
import { alpha } from '@mui/material';
import { common } from '@mui/material/colors';

type IconPreviewProps = Record<string, unknown> & {
  src: string;
};

const StyledIcon = styled(stripProps('figure', 'src'))<IconPreviewProps>(
  ({ src }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    width: 40,
    height: 40,
    backgroundImage: `url('${src}')`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%',
  }),
);

const IconHoverOverlay = styled('div')({
  content: '""',
  position: 'absolute',
  top: 0,
  left: 0,
  width: 40,
  height: 40,
  backgroundColor: alpha(common.black, 0.4),
  borderRadius: '50%',
});

const IconPreview = (props: IconPreviewProps) => (
  <>
    <StyledIcon className="perspective-icon" {...props} />
    <IconHoverOverlay className="perspective-icon__hover-overlay" />
  </>
);

export default IconPreview;
