import styled from '@emotion/styled';

type FlexWrapperProps = {
  centerContent?: boolean;
  alignItemsCenter?: boolean;
};

const FlexWrapper = styled('div')<FlexWrapperProps>(
  ({ centerContent, alignItemsCenter }) => ({
    display: 'flex',
    justifyContent: centerContent ? 'center' : undefined,
    alignItems: alignItemsCenter ? 'center' : undefined,
  }),
);

export default FlexWrapper;
