import FlexWrapper from '@/components/FlexWrapper';
import ShortcutTile from './ShortcutTile';
import TilesWrapper from './TilesWrapper';
import {
  School as SchoolsIcon,
  DriveFileMove as DataMigrationsIcon,
  AccountTree as PerspectivesIcon,
  PeopleAlt as UsersIcon,
} from '@mui/icons-material';

const ShortcutTiles = () => (
  <FlexWrapper centerContent>
    <TilesWrapper>
      <ShortcutTile egodactOnly>
        <ShortcutTile.Content icon={<SchoolsIcon />}>
          <ShortcutTile.Title>Schools</ShortcutTile.Title>
          <ShortcutTile.ContentText>
            Create a new school or manage existing school instances.
          </ShortcutTile.ContentText>
        </ShortcutTile.Content>
        <ShortcutTile.Link to="/schools">Manage schools</ShortcutTile.Link>
      </ShortcutTile>
      <ShortcutTile>
        <ShortcutTile.Content icon={<PerspectivesIcon />}>
          <ShortcutTile.Title>Perspectives</ShortcutTile.Title>
          <ShortcutTile.ContentText>
            Manage the content of the perspective module.
          </ShortcutTile.ContentText>
        </ShortcutTile.Content>
        <ShortcutTile.Link to="/perspectives">
          Manage perspectives
        </ShortcutTile.Link>
      </ShortcutTile>
      <ShortcutTile egodactOnly>
        <ShortcutTile.Content icon={<UsersIcon />}>
          <ShortcutTile.Title>Users</ShortcutTile.Title>
          <ShortcutTile.ContentText>
            Manage Egodact Admin users, or invite new users.
          </ShortcutTile.ContentText>
        </ShortcutTile.Content>
        <ShortcutTile.Link to="/users">Manage users</ShortcutTile.Link>
      </ShortcutTile>
      <ShortcutTile egodactOnly>
        <ShortcutTile.Content icon={<DataMigrationsIcon />}>
          <ShortcutTile.Title>Data migrations</ShortcutTile.Title>
          <ShortcutTile.ContentText>
            Import data from legacy Egodact instances.
          </ShortcutTile.ContentText>
        </ShortcutTile.Content>
        <ShortcutTile.Link to="/data-migrations">
          Manage data migrations
        </ShortcutTile.Link>
      </ShortcutTile>
    </TilesWrapper>
  </FlexWrapper>
);

export default ShortcutTiles;
