import { PerspectiveSublevel } from '@/routes/perspectives/types';
import useRefreshPerspectives from '../../useRefreshPerspectives';
import React, { useState } from 'react';
import useSyncedState from '@/lib/useSyncedState';
import { IconButton, TextField } from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import Dialog from '@/components/Dialog';
import api from '@/lib/api';
import Space from '@/components/Space';

type PerspectiveSublevelExplanationManagerProps = {
  perspectiveSublevel: PerspectiveSublevel;
};

const PerspectiveSublevelExplanationManager = ({
  perspectiveSublevel,
}: PerspectiveSublevelExplanationManagerProps) => {
  const refreshPerspectives = useRefreshPerspectives();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [explanation, setExplanation] = useSyncedState(
    perspectiveSublevel.explanation.text ?? '',
  );

  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    setEditDialogOpen(false);

    setSaving(true);

    await api.put(
      `/perspectives/sublevels/${perspectiveSublevel.id}/explanation`,
      {
        explanation,
      },
    );

    await refreshPerspectives();

    setSaving(false);
  };

  return (
    <>
      <IconButton
        className="visible-on-hover"
        size="small"
        onClick={() => setEditDialogOpen(true)}
        disabled={saving}
      >
        <InfoIcon />
      </IconButton>
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <Dialog.Title>Manage explanation</Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            Enter an explanation for <strong>{perspectiveSublevel.name}</strong>
            .
          </Dialog.ContentText>
          <Space height={20} />
          <TextField
            variant="outlined"
            placeholder="Explanation"
            value={explanation}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setExplanation(e.target.value)
            }
            minRows={6}
            maxRows={10}
            fullWidth
            multiline
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setEditDialogOpen(false)}
          />
          <Dialog.Actions.SaveButton
            onClick={handleSave}
            disabled={explanation.trim().length === 0}
          />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default PerspectiveSublevelExplanationManager;
