import useSwr from '@/lib/useSwr';
import { SchoolDataMigration } from '../../types';
import LoadingSpinner from '@/components/LoadingSpinner';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import DataMigrationStatusIndicator from './DataMigrationStatusIndicator';
import DataMigrationDetails from './DataMigrationDetails';
import styled from '@emotion/styled';

const SchoolIndicator = styled('span')(({ theme }) => ({
  marginLeft: 6,
  fontWeight: 400,
  color: theme.palette.text.secondary,
}));

const NoDataMigrationsText = styled('p')(({ theme }) => ({
  marginTop: 48,
  marginBottom: 48,
  fontWeight: 500,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const DataMigrationsOverview = () => {
  const {
    isLoading,
    data: dataMigrations,
    error,
  } = useSwr<SchoolDataMigration[]>('/data-migrations');

  if (isLoading) {
    return <LoadingSpinner margin={48} centered />;
  }

  if (error || !dataMigrations) {
    return (
      <Alert severity="error">
        Something went wrong while trying to load data migrations.
      </Alert>
    );
  }

  if (dataMigrations.length === 0) {
    return (
      <NoDataMigrationsText>No data migrations found</NoDataMigrationsText>
    );
  }

  return (
    <>
      {dataMigrations.map(dataMigration => (
        <Accordion key={dataMigration.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <strong>
              Data migration #{dataMigration.id}
              <SchoolIndicator>
                ({dataMigration.targetSchool.displayName})
              </SchoolIndicator>
            </strong>
            <DataMigrationStatusIndicator dataMigration={dataMigration} />
          </AccordionSummary>
          <AccordionDetails>
            <DataMigrationDetails dataMigration={dataMigration} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default DataMigrationsOverview;
