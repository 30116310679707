import SuperUserRole from '@/lib/constants/SuperUserRole';
import styled from '@emotion/styled';
import { MenuItem, Select } from '@mui/material';
import { SelectProps } from '@/lib/types';

const StyledSelect = styled(Select)({
  marginLeft: 12,
  minWidth: 160,
  maxWidth: 160,
  alignSelf: 'flex-start',
}) as unknown as typeof Select;

function RoleSelect<T>(props: SelectProps<T>) {
  return (
    <StyledSelect size="small" {...props}>
      <MenuItem value={SuperUserRole.LEIDEN_USER}>Leiden user</MenuItem>
      <MenuItem value={SuperUserRole.EGODACT_USER}>Egodact user</MenuItem>
    </StyledSelect>
  );
}

export default RoleSelect;
