import Underlined from '@/components/Underlined';
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';
import { Moment } from 'moment';

type ConfirmPublicationCheckboxProps = Omit<
  FormControlLabelProps,
  'control' | 'label'
> & {
  publishAt: Moment;
};

const ConfirmPublicationCheckbox = ({
  publishAt,
  ...props
}: ConfirmPublicationCheckboxProps) => (
  <FormControlLabel
    label={
      <>
        I understand that this will affect{' '}
        <Underlined>all Egodact users</Underlined> registering after{' '}
        <strong>{publishAt.format('L')}</strong>
      </>
    }
    control={<Checkbox />}
    {...props}
  />
);

export default ConfirmPublicationCheckbox;
