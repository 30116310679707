import { Perspective } from '@/routes/perspectives/types';
import { useState } from 'react';
import useRefreshPerspectives from '../useRefreshPerspectives';
import api from '@/lib/api';
import { IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Dialog from '@/components/Dialog';
import useIsDeletable from '../useIsDeletable';

type DeletePerspectiveProps = {
  perspective: Perspective;
};

const DeletePerspective = ({ perspective }: DeletePerspectiveProps) => {
  const refreshPerspectives = useRefreshPerspectives();

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setConfirmationDialogOpen(false);

    setDeleting(true);

    await api.delete(`/perspectives/${perspective.id}`);

    await refreshPerspectives();

    setDeleting(false);
  };

  const attachedTasksCount =
    perspective._count.directlyAttachedTasks +
    perspective._count.indirectlyAttachedTasks;

  const perspectiveIsDeletable = useIsDeletable(
    perspective.sublevels.length,
    attachedTasksCount,
  );

  if (!perspectiveIsDeletable) {
    return null;
  }

  return (
    <>
      <IconButton
        className="visible-on-hover"
        color="error"
        size="small"
        onClick={() => setConfirmationDialogOpen(true)}
        disabled={deleting}
      >
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
      >
        <Dialog.Title>Are you sure?</Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <strong>{perspective.name}</strong> will become permanently
            unavailable for all Egodact users. This action cannot be reversed.
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={handleDelete} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeletePerspective;
