import styled from '@emotion/styled';
import stripProps from '@/lib/stripProps';
import { CARD_WIDTH } from '../PerspectiveCard/styleConstants';

type InnerChunkWrapperProps = Record<string, unknown> & {
  visibleChunk: number;
  chunkCount: number;
};

const InnerChunkWrapper = styled(
  stripProps('div', 'visibleChunk', 'chunkCount'),
)<InnerChunkWrapperProps>(({ theme, visibleChunk, chunkCount }) => ({
  position: 'relative',
  left: visibleChunk * -1 * (CARD_WIDTH + 24),
  width: `calc(${chunkCount} * 100% + ${chunkCount - 1} * 24px)`,
  display: 'flex',
  transition: theme.transitions.create(['left']),
  '.perspective-manager-column': {
    paddingRight: 24,
    ':last-child': {
      paddingRight: 0,
    },
  },
}));

export default InnerChunkWrapper;
