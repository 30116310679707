import React, { useContext } from 'react';
import { InstanceConfig } from '@/routes/schools/types';
import InstanceConfigContext from './InstanceConfigContext';
import { Checkbox, FormControlLabel } from '@mui/material';
import styled from '@emotion/styled';

type BooleanConfigValueEditorProps = {
  label: string;
  configKey: keyof InstanceConfig;
};

const StyledFormControlLabel = styled(FormControlLabel)({
  marginBottom: -8,
  ':last-child': {
    marginBottom: 0,
  },
});

const BooleanConfigValueEditor = ({
  label,
  configKey,
}: BooleanConfigValueEditorProps) => {
  const [instanceConfig, setInstanceConfig] = useContext(InstanceConfigContext);

  const value = instanceConfig![configKey] as boolean;

  const handleChange = (_: React.SyntheticEvent, checked: boolean) => {
    setInstanceConfig(instanceConfig => ({
      ...instanceConfig,
      [configKey]: checked,
    }));
  };

  return (
    <StyledFormControlLabel
      label={label}
      checked={value}
      onChange={handleChange}
      control={<Checkbox />}
    />
  );
};

export default BooleanConfigValueEditor;
