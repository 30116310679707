import { Perspective, PerspectiveSublevel } from '@/routes/perspectives/types';
import PerspectiveCard from '../../PerspectiveCard';
import PerspectiveCardContent from '../../PerspectiveCard/PerspectiveCardContent';
import PerspectiveCardHeader from '../../PerspectiveCard/PerspectiveCardHeader';
import OpenSublevelsButton from '../../IndividualPerspective/OpenSublevelsButton';
import PerspectiveCardColorFill from '../../PerspectiveCard/PerspectiveCardColorFill';
import { Edit as EditIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import PerspectiveSublevelNameEditor from './PerspectiveSublevelNameEditor';
import DeletePerspectiveSublevel from './DeletePerspectiveSublevel';
import PerspectiveSublevelExplanationManager from './PerspectiveSublevelExplanationManager';

type IndividualPerspectiveSublevelProps = {
  perspectiveSublevel: PerspectiveSublevel;
  perspective: Perspective;
  onOpen: () => void;
  open: boolean;
  faded: boolean;
};

const IndividualPerspectiveSublevel = ({
  perspectiveSublevel,
  perspective,
  onOpen,
  open,
  faded,
}: IndividualPerspectiveSublevelProps) => {
  const [nameEditorOpen, setNameEditorOpen] = useState(false);

  return (
    <PerspectiveCard faded={faded}>
      <PerspectiveCardContent>
        <PerspectiveCardHeader fullSize>
          {nameEditorOpen ? (
            <PerspectiveSublevelNameEditor
              perspectiveSublevel={perspectiveSublevel}
              onClose={() => setNameEditorOpen(false)}
            />
          ) : (
            <>
              <h4>{perspectiveSublevel.name}</h4>
              <IconButton
                className="visible-on-hover"
                size="small"
                onClick={() => setNameEditorOpen(true)}
              >
                <EditIcon />
              </IconButton>
              <PerspectiveSublevelExplanationManager
                perspectiveSublevel={perspectiveSublevel}
              />
              <DeletePerspectiveSublevel
                perspectiveSublevel={perspectiveSublevel}
              />
            </>
          )}
        </PerspectiveCardHeader>
        <OpenSublevelsButton
          onClick={onOpen}
          asCloseButton={open}
          hidden={nameEditorOpen}
          fullSize
        />
      </PerspectiveCardContent>
      <PerspectiveCardColorFill color={perspective.color} small />
    </PerspectiveCard>
  );
};

export default IndividualPerspectiveSublevel;
