import { MutatorCallback, MutatorOptions, useSWRConfig } from 'swr';

type DataOrCallback<Data> = Data | MutatorCallback<Data>;

const useSwrMutate = <Data>(key: string) => {
  const { mutate } = useSWRConfig();

  const swrMutate = (
    dataOrCallback: DataOrCallback<Data>,
    options: MutatorOptions<Data> = {},
  ): Promise<Data | undefined> => {
    return mutate(key, dataOrCallback, {
      revalidate: false,
      ...options,
    });
  };

  return swrMutate;
};

export default useSwrMutate;
