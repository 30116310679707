import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

export type SpaceProps = {
  width?: number | string;
  height?: number | string;
};

const Space = styled('div', {
  shouldForwardProp: isPropValid,
})<SpaceProps>(({ width, height }) => {
  if (width) {
    return {
      marginRight: width,
      display: 'inline-block',
    };
  }

  return {
    marginBottom: height ?? 12,
  };
});

export default Space;
