import styled from '@emotion/styled';

const CONTENT_WIDTH = '(100vw - 300px)';
const WRAPPER_WIDTH = '900px';

const PerspectiveManagerWrapper = styled('section')({
  position: 'relative',
  marginLeft: `calc(-1 * ((${CONTENT_WIDTH} - ${WRAPPER_WIDTH}) / 2))`,
  marginRight: `calc(-1 * ((${CONTENT_WIDTH} - ${WRAPPER_WIDTH}) / 2))`,
  padding: '24px 36px',
  // Increasing the top padding is necessary to accomodate the perspective
  // column chunk controls
  paddingTop: 76,
  flex: 1,
  width: `calc(${CONTENT_WIDTH})`,
  boxSizing: 'border-box',
  display: 'flex',
  overflowX: 'auto',
});

export default PerspectiveManagerWrapper;
