import styled from '@emotion/styled';
import { IconButton, IconButtonProps } from '@mui/material';
import { common } from '@mui/material/colors';

const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  top: -2,
  left: -2,
  color: common.white,
  border: `2px solid transparent`,
});

const IconActionButton = ({ className, ...props }: IconButtonProps) => (
  <StyledIconButton
    className={`perspective-icon__action-button ${className ?? ''}`}
    {...props}
  />
);

export default IconActionButton;
