import MainPageTitle from '@/components/MainPageTitle';
import AppTitle from '@/components/AppTitle';
import { Button } from '@mui/material';
import { Add as CreateIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import PerspectiveCohortManager from './PerspectiveCohortManager';

const PerspectiveCohorts = () => (
  <>
    <AppTitle>Perspective cohorts</AppTitle>
    <MainPageTitle>
      Perspective cohorts
      <Button
        variant="contained"
        startIcon={<CreateIcon />}
        disableElevation
        component={Link}
        to="/perspectives/create"
      >
        Create cohort
      </Button>
    </MainPageTitle>
    <PerspectiveCohortManager />
  </>
);

export default PerspectiveCohorts;
