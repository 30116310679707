import { Perspective } from '@/routes/perspectives/types';
import {
  Clear as ClearIcon,
  Save as SaveIcon,
  AddPhotoAlternate as AddPhotoIcon,
} from '@mui/icons-material';
import { useState } from 'react';
import IconPreview from './components/IconPreview';
import IconActionButton from './components/IconActionButton';
import SecondaryIconActionButton from './components/SecondaryIconActionButton';
import UploadIconButton from './components/UploadIconButton';
import SavingIndicator from './components/SavingIndicator';
import useRefreshPerspectives from '../../useRefreshPerspectives';
import api, { HttpMethod } from '@/lib/api';
import useImageAsDataUrl from './useImageAsDataUrl';

type NewPerspectiveIconUploadProps = {
  perspective: Perspective;
};

const NewPerspectiveIconUpload = ({
  perspective,
}: NewPerspectiveIconUploadProps) => {
  const refreshPerspectives = useRefreshPerspectives();

  const [iconFile, setIconFile] = useState<File | null>(null);
  const iconAsDataUrl = useImageAsDataUrl(iconFile);

  const [saving, setSaving] = useState(false);

  const saveUpload = async () => {
    setSaving(true);

    const formData = new FormData();

    formData.append('icon', iconFile!);

    await api.call(`/perspectives/${perspective.id}/icon`, {
      method: HttpMethod.POST,
      body: formData,
    });

    await refreshPerspectives();

    setSaving(false);
  };

  if (iconFile === null) {
    return (
      <UploadIconButton onUpload={file => setIconFile(file)}>
        <AddPhotoIcon />
      </UploadIconButton>
    );
  }

  return (
    <>
      {iconAsDataUrl !== null && !saving && <IconPreview src={iconAsDataUrl} />}
      {iconFile !== null && !saving && (
        <>
          <IconActionButton onClick={saveUpload}>
            <SaveIcon />
          </IconActionButton>
          <SecondaryIconActionButton onClick={() => setIconFile(null)}>
            <ClearIcon />
          </SecondaryIconActionButton>
        </>
      )}
      {iconFile !== null && saving && <SavingIndicator />}
    </>
  );
};

export default NewPerspectiveIconUpload;
