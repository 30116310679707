/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PerspectiveManagerColumn from '../PerspectiveManagerColumn';
import chunk from 'lodash.chunk';
import { Perspective } from '@/routes/perspectives/types';
import AddPerspective from '../AddPerspective';
import { PERSPECTIVE_CHUNK_SIZE } from './constants';
import ChunkControls from './ChunkControls';
import OuterChunkWrapper from './OuterChunkWrapper';
import InnerChunkWrapper from './InnerChunkWrapper';

type PerspectiveColumnProps = Record<string, unknown> & {
  perspectives: Perspective[];
  onMove: () => void;
  children: (perspectives: Perspective[]) => React.ReactNode;
};

const PerspectiveColumn = ({
  perspectives,
  onMove,
  children,
  ...props
}: PerspectiveColumnProps) => {
  const perspectiveChunks = chunk(perspectives, PERSPECTIVE_CHUNK_SIZE);
  const lastChunk = Math.max(0, perspectiveChunks.length - 1);

  const [visibleChunk, setVisibleChunk] = useState(lastChunk);

  useEffect(() => {
    onMove();

    setVisibleChunk(lastChunk);
  }, [perspectiveChunks.length]);

  useEffect(() => {
    onMove();
  }, [visibleChunk]);

  return (
    <>
      {perspectiveChunks.length > 1 && (
        <ChunkControls
          chunkCount={perspectiveChunks.length}
          visibleChunk={visibleChunk}
          perspectiveCount={perspectives.length}
          visibleChunkPerspectiveCount={
            perspectiveChunks[visibleChunk]?.length ?? 0
          }
          setVisibleChunk={setVisibleChunk}
        />
      )}
      <OuterChunkWrapper>
        <InnerChunkWrapper
          visibleChunk={visibleChunk}
          chunkCount={perspectiveChunks.length}
        >
          {perspectiveChunks.length === 0 && (
            <PerspectiveManagerColumn>
              <AddPerspective />
            </PerspectiveManagerColumn>
          )}
          {perspectiveChunks.map((chunk, chunkIndex) => (
            <PerspectiveManagerColumn
              {...props}
              hidden={visibleChunk !== chunkIndex}
              key={chunkIndex}
            >
              {children(chunk)}
              {chunkIndex === lastChunk && <AddPerspective />}
            </PerspectiveManagerColumn>
          ))}
        </InnerChunkWrapper>
      </OuterChunkWrapper>
    </>
  );
};

export default PerspectiveColumn;
