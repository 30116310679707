import AppTitle from '@/components/AppTitle';
import Card from '@/components/Card';
import MainPageTitle from '@/components/MainPageTitle';
import PushToRight from '@/components/PushToRight';
import Space from '@/components/Space';
import api from '@/lib/api';
import ApiError from '@/lib/api/ApiError';
import { LoadingButton } from '@mui/lab';
import { Alert, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CreatePerspectiveCohort = () => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState<string | null>(null);

  const [saving, setSaving] = useState(false);
  const [genericSaveError, setGenericSaveError] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleSave = async () => {
    setGenericSaveError(null);

    const nameError = name.trim().length === 0 ? 'Please enter a name' : null;

    setNameError(nameError);

    if (nameError !== null) {
      return;
    }

    setSaving(true);

    try {
      await api.post('/perspectives/cohorts', {
        name,
      });

      navigate('/perspectives');
    } catch (error) {
      if (!(error instanceof ApiError)) {
        throw error;
      }

      setGenericSaveError(error.getErrorCode());
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <AppTitle>New perspective cohort</AppTitle>
      <MainPageTitle>New perspective cohort</MainPageTitle>
      {genericSaveError !== null && (
        <>
          <Alert severity="error">
            Could not save perspective cohort with error code:{' '}
            {genericSaveError}. Please try again later.
          </Alert>
          <Space height={24} />
        </>
      )}
      <Card>
        <Card.Title>
          <label htmlFor="name-field">Cohort name</label>
        </Card.Title>
        <TextField
          id="name-field"
          variant="outlined"
          placeholder="name"
          name="name"
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
          error={nameError !== null}
          helperText={nameError}
          size="small"
        />
      </Card>
      <PushToRight>
        <LoadingButton
          variant="contained"
          onClick={handleSave}
          loading={saving}
        >
          Create
        </LoadingButton>
      </PushToRight>
    </>
  );
};

export default CreatePerspectiveCohort;
