import Space from '@/components/Space';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const PasswordResetComplete = () => (
  <>
    <h1>Password reset complete</h1>
    <p>
      Your password was reset successfully. You can now sign in using your new
      password.
    </p>
    <Space height={12} />
    <Button
      variant="contained"
      disableElevation
      component={Link}
      to="/auth/login"
    >
      Go to sign-in page
    </Button>
  </>
);

export default PasswordResetComplete;
