import React, { forwardRef, useRef } from 'react';
import useForceUpdate from 'use-force-update';
import useKeydownListener from '@/lib/useKeydownListener';
import composeRefs from '@seznam/compose-react-refs';
import { TextField, TextFieldProps } from '@mui/material';

type TextFieldWithEscListenerProps = TextFieldProps & {
  onEscPress: (event: KeyboardEvent) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement, HTMLElement>) => void;
};

const TextFieldWithEscListener = forwardRef(
  (
    { onEscPress, onFocus, ...props }: TextFieldWithEscListenerProps,
    externalRef,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const forceUpdate = useForceUpdate();

    useKeydownListener(inputRef.current, (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onEscPress(event);
      }
    });

    return (
      <TextField
        {...props}
        inputRef={composeRefs(inputRef, externalRef)}
        onFocus={(event: React.FocusEvent<HTMLInputElement, HTMLElement>) => {
          // We need to force-update here to force the keydown listener to
          // "refresh". If we don't do this, it won't work until the user has
          // changed the input value
          forceUpdate();

          if (typeof onFocus === 'function') {
            onFocus(event);
          }
        }}
      />
    );
  },
);

TextFieldWithEscListener.displayName = 'TextFieldWithEscListener';

export default TextFieldWithEscListener;
