import { useContext } from 'react';
import PerspectiveManagerWrapperScrollContext from './PerspectiveManagerWrapperScrollContext';

const useScrollPerspectiveManagerWrapperToRight = () => {
  const scrollPerspectiveManagerWrapperToRight = useContext(
    PerspectiveManagerWrapperScrollContext,
  );

  return scrollPerspectiveManagerWrapperToRight;
};

export default useScrollPerspectiveManagerWrapperToRight;
