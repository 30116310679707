import styled from '@emotion/styled';
import { Paper } from '@mui/material';

const PaperAsUl = (props: Record<string, unknown>) => (
  <Paper component="ul" {...props} />
);

const PerspectiveCohortList = styled(PaperAsUl)({
  margin: 0,
  padding: 0,
  listStyle: 'none',
});

export default PerspectiveCohortList;
