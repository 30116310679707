import React from 'react';
import AppContentWrapper from './AppContentWrapper';
import AppContent from './AppContent';
import OuterAppWrapper from './OuterAppWrapper';
import AppDrawer from './AppDrawer';
import AppContentOuterWrapper from './AppContentOuterWrapper';
import AppHeader from './AppHeader';

type AppWrapperProps = {
  children: React.ReactNode;
};

const AppWrapper = ({ children }: AppWrapperProps) => (
  <OuterAppWrapper>
    <AppDrawer />
    <AppContentOuterWrapper>
      <AppHeader />
      <AppContentWrapper>
        <AppContent>{children}</AppContent>
      </AppContentWrapper>
    </AppContentOuterWrapper>
  </OuterAppWrapper>
);

export default AppWrapper;
