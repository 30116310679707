import React, { useContext } from 'react';
import ConfigGroupTitle from '../ConfigGroupTitle';
import InstanceConfigContext from '../InstanceConfigContext';
import { Checkbox, FormControlLabel } from '@mui/material';
import NameField from './NameField';

type CustomChallengeNamingUpdate = {
  enabled?: boolean;
  customChallengeNameSingular?: string;
  customChallengeNamePlural?: string;
};

const CustomChallengeNamingManager = () => {
  const [instanceConfig, setInstanceConfig] = useContext(InstanceConfigContext);

  const customChallengeNaming = instanceConfig!.customChallengeNaming;

  const updateChallengeNaming = (updates: CustomChallengeNamingUpdate) => {
    setInstanceConfig(instanceConfig => ({
      ...instanceConfig,
      customChallengeNaming: {
        ...instanceConfig.customChallengeNaming,
        ...updates,
      },
    }));
  };

  const handleToggleEnabled = (_: React.SyntheticEvent, checked: boolean) => {
    updateChallengeNaming({
      enabled: checked,
    });
  };

  const handleSingularNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateChallengeNaming({
      customChallengeNameSingular: e.target.value,
    });
  };

  const handlePluralNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateChallengeNaming({
      customChallengeNamePlural: e.target.value,
    });
  };

  return (
    <>
      <ConfigGroupTitle>Custom challenge naming</ConfigGroupTitle>
      <FormControlLabel
        label="Enable custom challenge naming"
        control={<Checkbox />}
        checked={customChallengeNaming.enabled}
        onChange={handleToggleEnabled}
      />
      {customChallengeNaming.enabled && (
        <>
          <NameField
            label="Challenge name (singular):"
            value={customChallengeNaming.customChallengeNameSingular ?? ''}
            onChange={handleSingularNameChange}
          />
          <NameField
            label="Challenge name (plural):"
            value={customChallengeNaming.customChallengeNamePlural ?? ''}
            onChange={handlePluralNameChange}
          />
        </>
      )}
    </>
  );
};

export default CustomChallengeNamingManager;
