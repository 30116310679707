import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';

const OuterWrapper = styled('div')({
  width: '100vw',
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const InnerWrapper = styled(Paper)({
  padding: 24,
  width: 500,
});

type AuthPageWrapperProps = {
  children: ReactNode;
};

const AuthPageWrapper = ({ children, ...props }: AuthPageWrapperProps) => (
  <OuterWrapper {...props}>
    <InnerWrapper variant="outlined">{children}</InnerWrapper>
  </OuterWrapper>
);

export default AuthPageWrapper;
