import moment from 'moment';
import { PerspectiveCohort } from '../../types';
import styled from '@emotion/styled';
import { blue, teal } from '@mui/material/colors';

type PerspectiveCohortPublishingStatusProps = {
  perspectiveCohort: PerspectiveCohort;
};

type PublishStatusIndicatorProps = {
  color?: string;
};

const PublishStatusIndicator = styled('span')<PublishStatusIndicatorProps>(
  ({ theme, color }) => ({
    marginRight: 24,
    color: color ?? theme.palette.text.secondary,
    lineHeight: 1,
    display: 'inline-flex',
    alignItems: 'center',
  }),
);

const PerspectiveCohortPublishingStatus = ({
  perspectiveCohort,
}: PerspectiveCohortPublishingStatusProps) => {
  if (perspectiveCohort.publishAt === null) {
    return <PublishStatusIndicator>not published</PublishStatusIndicator>;
  }

  const publishAt = new Date(perspectiveCohort.publishAt);

  if (publishAt > new Date()) {
    return (
      <PublishStatusIndicator color={blue[800]}>
        scheduled publication: {moment(publishAt).format('L')}
      </PublishStatusIndicator>
    );
  }

  return (
    <PublishStatusIndicator color={teal[700]}>
      published since {moment(publishAt).format('L')}
    </PublishStatusIndicator>
  );
};

export default PerspectiveCohortPublishingStatus;
