import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { grey } from '@mui/material/colors';

const SavingIndicator = styled(CircularProgress)({
  padding: 6,
  backgroundColor: grey[300],
  color: grey[500],
  // We need !important to override Material-UI styles
  width: '40px !important',
  height: '40px !important',
  boxSizing: 'border-box',
  borderRadius: '50%',
});

export default SavingIndicator;
