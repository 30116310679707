import styled from '@emotion/styled';
import { TextField, TextFieldProps } from '@mui/material';

type CardTextFieldProps = TextFieldProps & {
  width?: number | string;
};

const StyledTextField = styled(TextField)<CardTextFieldProps>(({ width }) => {
  if (width) {
    return {
      width,
    };
  }
});

const CardTextField = (props: CardTextFieldProps) => (
  <StyledTextField size="small" {...props} />
);

export default CardTextField;
