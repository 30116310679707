import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';

const SchoolUserCount = styled('div')({
  marginLeft: 'auto',
  marginRight: 16,
  color: grey[500],
  fontSize: 14,
  alignSelf: 'center',
});

export default SchoolUserCount;
