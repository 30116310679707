import useAuthenticationState from './components/AuthenticationState/useAuthenticationState';
import LoadingScreen from './components/LoadingScreen';
import ThemeProvider from './ThemeProvider';
import AppContentRenderer from './AppContentRenderer';
import AuthenticationStateContext from './components/AuthenticationState/AuthenticationStateContext';
import { Helmet } from 'react-helmet';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const App = () => {
  const [
    authenticationStateLoading,
    authenticatedSuperUser,
    refreshAuthenticationState,
  ] = useAuthenticationState();

  return (
    <>
      <Helmet titleTemplate="%s | Egodact Admin" />
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <LoadingScreen active={authenticationStateLoading}>
            <AuthenticationStateContext.Provider
              value={[authenticatedSuperUser, refreshAuthenticationState]}
            >
              <AppContentRenderer />
            </AuthenticationStateContext.Provider>
          </LoadingScreen>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
