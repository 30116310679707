import StyledAppBar from './StyledAppBar';
import { Toolbar } from '@mui/material';
import styled from '@emotion/styled';
import EgodactLogo from '@/components/EgodactLogo';
import Space from '@/components/Space';
import HeaderUserMenu from './HeaderUserMenu';

const StyledToolbar = styled(Toolbar)({
  margin: '0px auto',
  width: 900,
  display: 'flex',
});

const AppHeader = () => (
  <StyledAppBar>
    <StyledToolbar>
      <EgodactLogo />
      <Space width={12} />
      <h4>Admin tool</h4>
      <Space width="auto" />
      <HeaderUserMenu />
    </StyledToolbar>
  </StyledAppBar>
);

export default AppHeader;
