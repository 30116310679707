import Space from '@/components/Space';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const PasswordResetRequestedMessage = () => (
  <>
    <h1>Password reset request submitted</h1>
    <p>
      If the e-mail address you submitted is known in our system, you'll receive
      a password reset e-mail shortly.
    </p>
    <Space height={12} />
    <Button
      variant="contained"
      disableElevation
      component={Link}
      to="/auth/login"
    >
      Go to sign-in page
    </Button>
  </>
);

export default PasswordResetRequestedMessage;
