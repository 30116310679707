import { useState } from 'react';
import AppTitle from '@/components/AppTitle';
import PasswordResetRequestedMessage from './PasswordResetRequestedMessage';
import PasswordResetForm from './PasswordResetForm';

const RequestPasswordReset = () => {
  const [requestSubmitted, setRequestSubmitted] = useState(false);

  return (
    <>
      <AppTitle>Request password reset</AppTitle>
      {requestSubmitted ? (
        <PasswordResetRequestedMessage />
      ) : (
        <PasswordResetForm onComplete={() => setRequestSubmitted(true)} />
      )}
    </>
  );
};

export default RequestPasswordReset;
