import {
  PerspectiveCohort,
  PerspectiveCohortWithPerspectives,
} from '../../../types';
import Dialog from '@/components/Dialog';
import { Schedule as ScheduleIcon } from '@mui/icons-material';
import moment from 'moment';
import { useState, useEffect } from 'react';
import useSwrMutate from '@/lib/useSwrMutate';
import useSyncedState from '@/lib/useSyncedState';
import { LoadingButton } from '@mui/lab';
import DateCalendarWrapper from '../DateCalendarWrapper';
import { DateCalendar } from '@mui/x-date-pickers';
import ConfirmPublicationCheckbox from '../ConfirmPublicationCheckbox';
import api from '@/lib/api';
import { ButtonGroup } from '@mui/material';
import CancelScheduledPerspectiveCohortPublication from './CancelScheduledPerspectiveCohortPublication';

type ManageScheduledPerspectiveCohortPublishingProps = {
  perspectiveCohort: PerspectiveCohort;
};

const ManageScheduledPerspectiveCohortPublishing = ({
  perspectiveCohort,
}: ManageScheduledPerspectiveCohortPublishingProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [publishAt, setPublishAt] = useSyncedState(
    moment(perspectiveCohort.publishAt),
  );
  const [actionConfirmed, setActionConfirmed] = useState(false);

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setActionConfirmed(false);
  }, [dialogOpen, publishAt]);

  const mutate = useSwrMutate<PerspectiveCohortWithPerspectives>(
    `/perspectives/cohorts/${perspectiveCohort.id}`,
  );

  const handleUpdateScheduledPublication = async () => {
    setDialogOpen(false);

    setSaving(true);

    await api.post(`/perspectives/cohorts/${perspectiveCohort.id}/publish`, {
      publishAt: publishAt.toDate(),
    });

    mutate(perspectiveCohort => {
      if (typeof perspectiveCohort === 'undefined') {
        return;
      }

      return {
        ...perspectiveCohort,
        publishAt: publishAt.toDate().toISOString(),
      };
    });

    setSaving(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" disableElevation>
        <LoadingButton
          variant="contained"
          startIcon={<ScheduleIcon />}
          loadingPosition="start"
          onClick={() => setDialogOpen(true)}
          loading={saving}
          disableElevation
        >
          Auto-publish: {moment(perspectiveCohort.publishAt).format('L')}
        </LoadingButton>
        <CancelScheduledPerspectiveCohortPublication
          perspectiveCohort={perspectiveCohort}
        />
      </ButtonGroup>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Dialog.Title>Change scheduled publication</Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            Select a new publication date for{' '}
            <strong>{perspectiveCohort.name}</strong>. Users that join Egodact
            after this publication date will automatically be assigned to this
            perspective cohort.
          </Dialog.ContentText>
        </Dialog.Content>
        <DateCalendarWrapper>
          <DateCalendar
            value={publishAt}
            onChange={value => setPublishAt(value ?? moment())}
          />
        </DateCalendarWrapper>
        <Dialog.Content>
          <ConfirmPublicationCheckbox
            publishAt={publishAt}
            checked={actionConfirmed}
            onChange={(_, checked: boolean) => setActionConfirmed(checked)}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton onClick={() => setDialogOpen(false)} />
          <Dialog.Actions.SaveButton
            color="warning"
            onClick={handleUpdateScheduledPublication}
            disabled={!actionConfirmed}
          />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default ManageScheduledPerspectiveCohortPublishing;
