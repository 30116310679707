import Dialog from '@/components/Dialog';
import styled from '@emotion/styled';

const DateCalendarWrapper = styled(Dialog.Content)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  '.MuiDateCalendar-root': {
    marginLeft: 0,
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: 4,
  },
}));

export default DateCalendarWrapper;
