import FlexWrapper from '@/components/FlexWrapper';
import { School } from '../../types';
import moment from 'moment';
import { Button } from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import Space from '@/components/Space';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { common, grey } from '@mui/material/colors';
import AccessSchoolInstanceButton from '../../components/AccessSchoolInstanceButton';

type SchoolDetailsProps = {
  school: School;
};

const BasicInfoWrapper = styled('div')({
  color: grey[700],
  'em, code': {
    color: common.black,
  },
  em: {
    fontStyle: 'normal',
  },
});

const SchoolDetails = ({ school }: SchoolDetailsProps) => (
  <>
    <BasicInfoWrapper>
      <div>
        Active since: <em>{moment(school.createdAt).format('L')}</em>
      </div>
      <Space height={4} />
      <div>
        Internal reference: <code>{school.reference}</code>
      </div>
    </BasicInfoWrapper>
    <Space height={16} />
    <FlexWrapper>
      <AccessSchoolInstanceButton schoolReference={school.reference} />
      <Space width="auto" />
      <Button
        variant="contained"
        startIcon={<SettingsIcon />}
        disableElevation
        component={Link}
        to={`/schools/${school.id}`}
      >
        Manage school
      </Button>
    </FlexWrapper>
  </>
);

export default SchoolDetails;
