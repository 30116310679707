import { Perspective } from '@/routes/perspectives/types';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { Palette as ColorPaletteIcon } from '@mui/icons-material';
import useSyncedState from '@/lib/useSyncedState';
import React, { useState } from 'react';
import useRefreshPerspectives from '../useRefreshPerspectives';
import api from '@/lib/api';

type ColorPickerProps = {
  perspective: Perspective;
};

const ColorInput = styled('input')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: 40,
  height: 40,
  background: 'none',
  opacity: 0,
  cursor: 'pointer',
  appearance: 'none',
});

const ColorPicker = ({ perspective }: ColorPickerProps) => {
  const refreshPerspectives = useRefreshPerspectives();

  const [color, setColor] = useSyncedState(perspective.color);
  const [saving, setSaving] = useState(false);

  const saveColor = async () => {
    setSaving(true);

    await api.put(`perspectives/${perspective.id}/color`, {
      color,
    });

    await refreshPerspectives();

    setSaving(false);
  };

  return (
    <IconButton disabled={saving} tabIndex={-1}>
      <ColorInput
        type="color"
        value={color}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setColor(e.target.value)
        }
        onBlur={saveColor}
        disabled={saving}
      />
      <ColorPaletteIcon />
    </IconButton>
  );
};

export default ColorPicker;
