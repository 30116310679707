import { Button } from '@mui/material';
import { OpenInNew as OpenLinkIcon } from '@mui/icons-material';

type AccessSchoolInstanceButtonProps = {
  schoolReference: string;
};

const AccessSchoolInstanceButton = ({
  schoolReference,
}: AccessSchoolInstanceButtonProps) => {
  const instanceUrl = `${
    import.meta.env.VITE_EGODACT_APP_URL
  }?school=${schoolReference}`;

  return (
    <Button
      variant="outlined"
      color="secondary"
      startIcon={<OpenLinkIcon />}
      component="a"
      href={instanceUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      Access instance
    </Button>
  );
};

export default AccessSchoolInstanceButton;
