import AuthProviderSelect from '../UpdateSchool/InstanceConfigManager/InstanceConfigEditor/AuthProviderSelect';
import CustomChallengeNamingManager from '../UpdateSchool/InstanceConfigManager/InstanceConfigEditor/CustomChallengeNamingManager';
import ConfigGroupTitle from '../UpdateSchool/InstanceConfigManager/InstanceConfigEditor/ConfigGroupTitle';
import { FormGroup } from '@mui/material';
import BooleanConfigValueEditor from '../UpdateSchool/InstanceConfigManager/InstanceConfigEditor/BooleanConfigValueEditor';

const InstanceConfigForm = () => (
  <>
    <AuthProviderSelect />
    <CustomChallengeNamingManager />
    <ConfigGroupTitle>Content settings</ConfigGroupTitle>
    <FormGroup>
      <BooleanConfigValueEditor
        label="Enable perspective module"
        configKey="enablePerspectiveModule"
      />
      <BooleanConfigValueEditor
        label="Use right-to-left sliders"
        configKey="useReversedSliders"
      />
      <BooleanConfigValueEditor
        label="Enable arrows for selecting users in the coach mode"
        configKey="enableUserSwitcherArrows"
      />
      <BooleanConfigValueEditor
        label="Use profile pictures to identify users"
        configKey="useProfilePictures"
      />
      <BooleanConfigValueEditor
        label="Enable more extensive rubric collapsing controls"
        configKey="enableAggressiveRubricCollapsing"
      />
    </FormGroup>
  </>
);

export default InstanceConfigForm;
