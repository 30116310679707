import stripProps from '@/lib/stripProps';
import useRandomColor from '@/lib/styling/useRandomColor';
import styled from '@emotion/styled';
import { Avatar, AvatarProps } from '@mui/material';

type RandomColorAvatarProps = AvatarProps & {
  size?: number;
};

type StyledAvatarProps = RandomColorAvatarProps & {
  size: number;
  avatarColor: string;
};

const StyledAvatar = styled(stripProps(Avatar, 'avatarColor'))<
  StyledAvatarProps
>(({ avatarColor, size }) => ({
  width: size,
  height: size,
  fontSize: size / 2,
  backgroundColor: avatarColor,
}));

const RandomColorAvatar = ({ size, ...props }: RandomColorAvatarProps) => {
  const randomColor = useRandomColor(500);

  return (
    <StyledAvatar avatarColor={randomColor} size={size ?? 40} {...props} />
  );
};

export default RandomColorAvatar;
