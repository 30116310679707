import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import CardTitle from './CardTitle';
import CardTextField from './CardTextField';

const StyledCard = styled(Paper)({
  marginBottom: 24,
  padding: 18,
  ':last-child': {
    marginBottom: 0,
  },
});

const Card = (props: Record<string, unknown>) => <StyledCard {...props} />;

Card.Title = CardTitle;
Card.TextField = CardTextField;

export default Card;
