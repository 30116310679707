import { useState } from 'react';
import EmailPasswordForm from './EmailPasswordForm';
import OtpForm from './OtpForm';

type LoginFlowProps = {
  onComplete: (accessToken: string) => void;
};

enum LoginFlowStage {
  EMAIL_PASSWORD,
  OTP,
}

const LoginFlow = ({ onComplete }: LoginFlowProps) => {
  const [stage, setStage] = useState(LoginFlowStage.EMAIL_PASSWORD);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  if (stage === LoginFlowStage.EMAIL_PASSWORD) {
    return (
      <EmailPasswordForm
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        onNext={() => setStage(LoginFlowStage.OTP)}
      />
    );
  }

  return (
    <OtpForm email={email} password={password} onCompleteLogin={onComplete} />
  );
};

export default LoginFlow;
