import {
  PerspectiveCohort,
  PerspectiveCohortWithPerspectives,
} from '@/routes/perspectives/types';
import { LoadingButton } from '@mui/lab';
import { Cancel as CancelIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
import Dialog from '@/components/Dialog';
import useSwrMutate from '@/lib/useSwrMutate';
import api from '@/lib/api';

type CancelScheduledPerspectiveCohortPublicationProps = {
  perspectiveCohort: PerspectiveCohort;
};

const CancelScheduledPerspectiveCohortPublication = ({
  perspectiveCohort,
}: CancelScheduledPerspectiveCohortPublicationProps) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const mutate = useSwrMutate<PerspectiveCohortWithPerspectives>(
    `/perspectives/cohorts/${perspectiveCohort.id}`,
  );

  const handleCancelScheduledPublication = async () => {
    setConfirmationDialogOpen(false);

    setSaving(true);

    await api.post(`/perspectives/cohorts/${perspectiveCohort.id}/unpublish`);

    mutate(perspectiveCohort => {
      if (typeof perspectiveCohort === 'undefined') {
        return;
      }

      return {
        ...perspectiveCohort,
        publishAt: null,
      };
    });

    setSaving(false);
  };

  return (
    <>
      <Tooltip title="Cancel auto-publish" arrow>
        <LoadingButton
          variant="contained"
          color="warning"
          aria-label="Cancel auto-publish"
          onClick={() => setConfirmationDialogOpen(true)}
          loading={saving}
          disableElevation
        >
          <CancelIcon />
        </LoadingButton>
      </Tooltip>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>Cancel scheduled publication</Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            Are you sure you want to cancel publication of{' '}
            <strong>{perspectiveCohort.name}</strong>?
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton
            onClick={handleCancelScheduledPublication}
          >
            Yes, cancel
          </Dialog.Actions.DeleteButton>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default CancelScheduledPerspectiveCohortPublication;
