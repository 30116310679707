const ACCESS_TOKEN_LOCAL_STORAGE_KEY =
  '9rX9n7QOclxfWLCX0PYIzBESFv5q6hmA_CACHE_2';

class ApiAuthLocalStorage {
  getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  }

  saveAccessToken(accessToken: string): void {
    localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, accessToken);
  }

  clearAccessToken(): void {
    localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  }
}

export default new ApiAuthLocalStorage();
