import { InstanceConfig, IdentityConnectionProvider } from '../types';

const defaultInstanceConfig: InstanceConfig = {
  allowedEmails: [],
  authProvider: IdentityConnectionProvider.GOOGLE,
  enablePerspectiveModule: false,
  useReversedSliders: true,
  enableUserSwitcherArrows: false,
  useProfilePictures: true,
  enableAggressiveRubricCollapsing: false,
  customChallengeNaming: {
    enabled: false,
  },
};

export default defaultInstanceConfig;
