import { ButtonProps, useMediaQuery } from '@mui/material';
import StyledButton from './StyledButton';

const DialogButton = (props: ButtonProps) => {
  const shouldUseMobileVariant = useMediaQuery('(max-width: 640px)');

  return (
    <StyledButton
      variant="contained"
      fullWidth={shouldUseMobileVariant}
      disableElevation
      {...props}
    />
  );
};

export default DialogButton;
