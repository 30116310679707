import styled from '@emotion/styled';
import { Button, ButtonProps } from '@mui/material';

const BUTTON_SIZE = 18;

const StyledButton = styled(Button)({
  position: 'absolute',
  top: -4,
  right: -6,
  padding: 0,
  width: BUTTON_SIZE,
  minWidth: BUTTON_SIZE,
  height: BUTTON_SIZE,
  borderRadius: '50%',
  '.MuiSvgIcon-root': {
    width: BUTTON_SIZE - 4,
    height: BUTTON_SIZE - 4,
  },
});

const SecondaryIconActionButton = (props: ButtonProps) => (
  <StyledButton
    className="perspective-icon__secondary-action-button"
    variant="contained"
    disableElevation
    {...props}
  />
);

export default SecondaryIconActionButton;
