import { SuperUser } from '@/routes/super-users/types';
import { createContext } from 'react';

type AuthenticationStateContextType = [SuperUser | null, () => void];

const AuthenticationStateContext = createContext<
  AuthenticationStateContextType
>([null, () => {}]);

export default AuthenticationStateContext;
