import { useState, useEffect } from 'react';
import api from '@/lib/api';
import ApiAuthLocalStorage from '@/lib/api/ApiAuthLocalStorage';
import { SuperUser } from '@/routes/super-users/types';

const useAuthenticationState = (): [
  boolean,
  SuperUser | null,
  () => Promise<void>,
] => {
  const [loading, setLoading] = useState(true);
  const [authenticatedSuperUser, setAuthenticatedSuperUser] = useState(null);

  const refreshAuthenticationState = async () => {
    const accessToken = ApiAuthLocalStorage.getAccessToken();

    if (!accessToken) {
      setAuthenticatedSuperUser(null);
      setLoading(false);

      return;
    }

    try {
      const authenticatedSuperUser = await api.get('auth/me');

      setAuthenticatedSuperUser(authenticatedSuperUser);
    } catch {
      setAuthenticatedSuperUser(null);

      api.unauthenticate();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshAuthenticationState();
  }, []);

  return [loading, authenticatedSuperUser, refreshAuthenticationState];
};

export default useAuthenticationState;
