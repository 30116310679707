const validatePasswordConfirmation = (
  password: string,
  passwordConfirmation: string,
): string | null => {
  if (passwordConfirmation.length === 0) {
    return 'Please enter your password confirmation';
  }

  if (password !== passwordConfirmation) {
    return "Password and password confirmation don't match";
  }

  return null;
};

export default validatePasswordConfirmation;
