import React from 'react';
import styled from '@emotion/styled';
import mediaQuery from '@/lib/styling/mediaQuery';

export type DialogActionsWrapperProps = {
  addTopMargin?: boolean;
  children: React.ReactNode;
};

const DialogActionsWrapper = styled('footer')<DialogActionsWrapperProps>(
  ({ addTopMargin }) => ({
    paddingTop: 8,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [mediaQuery(640)]: {
      paddingTop: addTopMargin ? 32 : 8,
      paddingBottom: 16,
      paddingLeft: 24,
      paddingRight: 24,
      display: 'initial',
    },
  }),
);

export default DialogActionsWrapper;
