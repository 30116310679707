import { PerspectiveSublevel } from '@/routes/perspectives/types';
import useRefreshPerspectives from '../../useRefreshPerspectives';
import useSyncedState from '@/lib/useSyncedState';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import api from '@/lib/api';
import TextFieldWithEscListener from '@/components/TextFieldWithEscListener';
import Space from '@/components/Space';
import { IconButton } from '@mui/material';
import { Check as SaveIcon } from '@mui/icons-material';

type PerspectiveSublevelNameEditorProps = {
  perspectiveSublevel: PerspectiveSublevel;
  onClose: () => void;
};

const StyledForm = styled('form')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const PerspectiveSublevelNameEditor = ({
  perspectiveSublevel,
  onClose,
}: PerspectiveSublevelNameEditorProps) => {
  const refreshPerspectives = useRefreshPerspectives();

  const [name, setName] = useSyncedState(perspectiveSublevel.name);
  const [saving, setSaving] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setSaving(true);

    await api.put(`/perspectives/sublevels/${perspectiveSublevel.id}/name`, {
      name,
    });
    await refreshPerspectives();

    setSaving(false);
    onClose();
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <TextFieldWithEscListener
        variant="outlined"
        placeholder="name"
        value={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setName(e.target.value)
        }
        size="small"
        autoFocus
        onEscPress={onClose}
      />
      <Space width={8} />
      <IconButton
        size="small"
        disabled={name.trim().length === 0 || saving}
        type="submit"
      >
        <SaveIcon />
      </IconButton>
    </StyledForm>
  );
};

export default PerspectiveSublevelNameEditor;
