import useAuthenticatedSuperUser from '@/components/AuthenticationState/useAuthenticatedSuperUser';
import styled from '@emotion/styled';
import MainPageTitle from '@/components/MainPageTitle';
import RandomColorAvatar from '@/components/RandomColorAvatar';
import Space from '@/components/Space';

const StyledHeader = styled('header')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const UserHeader = () => {
  const authenticatedSuperUser = useAuthenticatedSuperUser()!;

  return (
    <StyledHeader>
      <RandomColorAvatar size={80}>
        {authenticatedSuperUser.profile.firstName.charAt(0)}
      </RandomColorAvatar>
      <Space height={18} />
      <MainPageTitle>
        Welcome, {authenticatedSuperUser.profile.name}
      </MainPageTitle>
    </StyledHeader>
  );
};

export default UserHeader;
