import styled from '@emotion/styled';
import { common } from '@mui/material/colors';

const PerspectiveCardIcon = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: 12,
  alignSelf: 'center',
  width: 40,
  height: 40,
  backgroundColor: common.white,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `4px solid ${common.white}`,
  borderRadius: '50%',
  zIndex: 1,
  [`.perspective-icon__hover-overlay,
    .perspective-icon__action-button,
    .perspective-icon__secondary-action-button
  `]: {
    transition: theme.transitions.create('all'),
    opacity: 0,
  },
  '.perspective-icon__secondary-action-button': {
    transform: 'scale(0)',
  },
  [`:hover .perspective-icon__hover-overlay,
    :hover .perspective-icon__action-button,
    :hover .perspective-icon__secondary-action-button,
    .perspective-icon__action-button:focus,
    .perspective-icon__secondary-action-button:focus
  `]: {
    opacity: 1,
  },
  [`:hover .perspective-icon__secondary-action-button,
   .perspective-icon__secondary-action-button:focus
  `]: {
    transform: 'scale(1)',
  },
}));

export default PerspectiveCardIcon;
