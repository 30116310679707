import AppTitle from '@/components/AppTitle';
import MainPageTitle from '@/components/MainPageTitle';
import React, { useState } from 'react';
import { Alert, TextField } from '@mui/material';
import Card from '@/components/Card';
import defaultInstanceConfig from './defaultInstanceConfig';
import useAllowedEmailsState from '../UpdateSchool/InstanceConfigManager/InstanceConfigEditor/useAllowedEmailsState';
import InstanceConfigContext from '../UpdateSchool/InstanceConfigManager/InstanceConfigEditor/InstanceConfigContext';
import AllowedEmailManager from '../UpdateSchool/InstanceConfigManager/InstanceConfigEditor/AllowedEmailManager';
import PushToRight from '@/components/PushToRight';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import ApiError from '@/lib/api/ApiError';
import Space from '@/components/Space';
import api from '@/lib/api';
import validateReference from '@/lib/validation/validateReference';
import FlexWrapper from '@/components/FlexWrapper';
import TextFieldLabel from './TextFieldLabel';
import InstanceConfigForm from './InstanceConfigForm';

type SchoolErrors = {
  reference: string | null;
  displayName: string | null;
};

const CreateSchool = () => {
  const [reference, setReference] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [instanceConfig, setInstanceConfig] = useState(defaultInstanceConfig);

  const {
    allowedEmails,
    allowedEmailsAsObject: newAllowedEmails,
    addAllowedEmail,
    editAllowedEmail,
    removeAllowedEmail,
  } = useAllowedEmailsState(instanceConfig.allowedEmails);

  const [schoolErrors, setSchoolErrors] = useState<SchoolErrors>({
    reference: null,
    displayName: null,
  });

  const [saving, setSaving] = useState(false);
  const [genericSaveError, setGenericSaveError] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleSave = async () => {
    setGenericSaveError(null);

    const schoolErrors = {
      reference: validateReference(reference),
      displayName:
        displayName.trim().length === 0 ? 'Please enter a display name' : null,
    };

    setSchoolErrors(schoolErrors);

    if (schoolErrors.reference !== null || schoolErrors.displayName !== null) {
      return;
    }

    setSaving(true);

    const instanceConfigValues = Object.entries({
      ...instanceConfig,
      allowedEmails: Object.values(newAllowedEmails),
    }).map(([reference, value]) => ({
      reference,
      value: JSON.stringify(value),
    }));

    try {
      await api.post('/schools', {
        reference,
        displayName,
        instanceConfigValues,
      });

      navigate('/schools');
    } catch (error) {
      if (!(error instanceof ApiError)) {
        throw error;
      }

      setGenericSaveError(error.getErrorCode());
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <AppTitle>New school</AppTitle>
      <MainPageTitle>New school</MainPageTitle>
      {genericSaveError !== null && (
        <>
          <Alert severity="error">
            Could not save school with error code: {genericSaveError}. Please
            try again later.
          </Alert>
          <Space height={24} />
        </>
      )}
      <Card>
        <FlexWrapper>
          <TextFieldLabel htmlFor="reference-field">Reference:</TextFieldLabel>
          <TextField
            id="reference-field"
            variant="outlined"
            name="reference"
            placeholder="value"
            value={reference}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setReference(e.target.value)
            }
            error={schoolErrors.reference !== null}
            helperText={schoolErrors.reference}
            size="small"
          />
        </FlexWrapper>
        <Space height={12} />
        <FlexWrapper>
          <TextFieldLabel htmlFor="display-name-field">
            Display name:
          </TextFieldLabel>
          <TextField
            id="display-name-field"
            variant="outlined"
            name="display_name"
            placeholder="value"
            value={displayName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDisplayName(e.target.value)
            }
            error={schoolErrors.displayName !== null}
            helperText={schoolErrors.displayName}
            size="small"
          />
        </FlexWrapper>
      </Card>
      <Card>
        <Card.Title>Instance configuration</Card.Title>
        <InstanceConfigContext.Provider
          value={[instanceConfig, setInstanceConfig]}
        >
          <AllowedEmailManager
            allowedEmails={allowedEmails}
            addAllowedEmail={addAllowedEmail}
            editAllowedEmail={editAllowedEmail}
            removeAllowedEmail={removeAllowedEmail}
          />
          <InstanceConfigForm />
        </InstanceConfigContext.Provider>
      </Card>
      <PushToRight>
        <LoadingButton
          variant="contained"
          onClick={handleSave}
          loading={saving}
        >
          Create
        </LoadingButton>
      </PushToRight>
    </>
  );
};

export default CreateSchool;
