import styled from '@emotion/styled';
import { ListItemButton } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  '&.active': {
    backgroundColor: theme.palette.action.selected,
    ':hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
}));

type DrawerLinkProps = {
  to: string;
  children: React.ReactNode;
};

const DrawerLink = (props: DrawerLinkProps) => (
  <ListItemButton component={StyledNavLink} {...props} />
);

export default DrawerLink;
