import AllowedEmailForm from './AllowedEmailForm';
import AddAllowedEmail from './AddAllowedEmail';
import Space from '@/components/Space';
import { AllowedEmail } from '@/routes/schools/types';
import { AllowedEmailsWithId } from '../useAllowedEmailsState';
import ConfigGroupTitle from '../ConfigGroupTitle';

type AllowedEmailManagerProps = {
  allowedEmails: AllowedEmailsWithId;
  addAllowedEmail: (allowedEmail: AllowedEmail) => void;
  editAllowedEmail: (id: string, allowedEmail: AllowedEmail) => void;
  removeAllowedEmail: (id: string) => void;
};

const AllowedEmailManager = ({
  allowedEmails,
  addAllowedEmail,
  editAllowedEmail,
  removeAllowedEmail,
  ...props
}: AllowedEmailManagerProps) => (
  <>
    <ConfigGroupTitle>Allowed e-mail domains</ConfigGroupTitle>
    <Space height={8} />
    {allowedEmails.map(({ id, allowedEmail }) => (
      <AllowedEmailForm
        allowedEmail={allowedEmail}
        onEdit={newAllowedEmail => editAllowedEmail(id, newAllowedEmail)}
        onRemove={() => removeAllowedEmail(id)}
        key={id}
        {...props}
      />
    ))}
    <AddAllowedEmail allowedEmails={allowedEmails} onAdd={addAllowedEmail} />
  </>
);

export default AllowedEmailManager;
