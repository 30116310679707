import styled from '@emotion/styled';

const AppContentWrapper = styled('div')({
  padding: 48,
  minHeight: 'calc(100vh - 65px)',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
});

export default AppContentWrapper;
