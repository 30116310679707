import MainPageTitle from '@/components/MainPageTitle';
import { Button } from '@mui/material';
import { Add as CreateIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import SuperUserList from './SuperUserList';
import PageTitle from '@/components/AppTitle';

const SuperUserManager = () => (
  <>
    <PageTitle>Users</PageTitle>
    <MainPageTitle>
      Users
      <Button
        variant="contained"
        startIcon={<CreateIcon />}
        disableElevation
        component={Link}
        to="/users/create"
      >
        Create user
      </Button>
    </MainPageTitle>
    <SuperUserList />
  </>
);

export default SuperUserManager;
