import styled from '@emotion/styled';

const NotFoundMessage = styled('h2')(({ theme }) => ({
  paddingTop: 48,
  paddingBottom: 48,
  flex: 1,
  fontSize: 18,
  color: theme.palette.text.secondary,
  transition: theme.transitions.create(['color']),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default NotFoundMessage;
