import React from 'react';
import styled from '@emotion/styled';
import { Error as ErrorIcon } from '@mui/icons-material';
import Space from '@/components/Space';

type ErrorScreenTitleProps = {
  children: React.ReactNode;
};

const StyledTitle = styled('h1')({
  display: 'flex',
  alignItems: 'center',
});

const ErrorScreenTitle = ({ children }: ErrorScreenTitleProps) => (
  <StyledTitle>
    <ErrorIcon color="error" fontSize="large" />
    <Space width={12} />
    {children}
  </StyledTitle>
);

export default ErrorScreenTitle;
