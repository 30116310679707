import styled from '@emotion/styled';

type SchoolCountProps = {
  schoolCount: number;
};

const StyledSchoolCount = styled('div')({
  marginTop: 24,
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.4)',
  fontWeight: 500,
});

const SchoolCount = ({ schoolCount }: SchoolCountProps) => (
  <StyledSchoolCount>
    {schoolCount === 0 && 'No active schools'}
    {schoolCount === 1 && '1 active school'}
    {schoolCount > 1 && `${schoolCount} active schools`}
  </StyledSchoolCount>
);

export default SchoolCount;
