import MainPageTitle from '@/components/MainPageTitle';
import AppTitle from '@/components/AppTitle';
import { Button } from '@mui/material';
import { Add as CreateIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import DataMigrationsOverview from './DataMigrationsOverview';

const DataMigrationsManager = () => (
  <>
    <AppTitle>Data migrations</AppTitle>
    <MainPageTitle>
      Data migrations
      <Button
        variant="contained"
        startIcon={<CreateIcon />}
        disableElevation
        component={Link}
        to="/data-migrations/create"
      >
        Create data migration
      </Button>
    </MainPageTitle>
    <DataMigrationsOverview />
  </>
);

export default DataMigrationsManager;
