import AppTitle from '@/components/AppTitle';
import { useParams } from 'react-router-dom';
import useSwr from '@/lib/useSwr';
import LoadingSpinner from '@/components/LoadingSpinner';
import { Alert } from '@mui/material';
import PasswordResetFlow from './PasswordResetFlow';

type PasswordResetQueryParams = {
  token: string;
};

const PasswordReset = () => {
  const { token } = useParams() as PasswordResetQueryParams;
  const { isLoading, data: resetTokenInfo, error } = useSwr(
    `auth/password-reset/status/${token}`,
  );

  return (
    <>
      <AppTitle>Reset your password</AppTitle>
      {isLoading && <LoadingSpinner margin={32} centered />}
      {!isLoading && error && (
        <Alert severity="error">
          Something went wrong while trying to load your password reset token.
        </Alert>
      )}
      {!isLoading && resetTokenInfo?.expired && (
        <Alert severity="error">
          Your password reset token could not be found. It might have expired.
          Try requesting a new password reset token.
        </Alert>
      )}
      {!isLoading && !error && !resetTokenInfo?.expired && (
        <PasswordResetFlow token={token} />
      )}
    </>
  );
};

export default PasswordReset;
