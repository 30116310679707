import DialogButton from './DialogButton';
import { ButtonProps } from '@mui/material';

const DialogDoneButton = ({ children, ...props }: ButtonProps) => (
  <DialogButton color="primary" {...props}>
    {children ?? 'Done'}
  </DialogButton>
);

export default DialogDoneButton;
