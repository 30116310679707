import React, { useState } from 'react';
import Space from '@/components/Space';
import { TextField } from '@mui/material';
import PushToRight from '@/components/PushToRight';
import { LoadingButton } from '@mui/lab';
import validateEmail from '@/lib/validation/validateEmail';
import api from '@/lib/api';

type PasswordResetFormProps = {
  onComplete: () => void;
};

const PasswordResetForm = ({ onComplete }: PasswordResetFormProps) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const emailError = validateEmail(email);

    setEmailError(emailError);

    if (emailError !== null) {
      return;
    }

    setLoading(true);

    await api.post('/auth/password-reset/request', {
      email,
    });

    setLoading(false);

    onComplete();
  };

  return (
    <>
      <h1>Request password reset</h1>
      <p>
        Enter your e-mail address below. If a user exists with your e-mail
        address, we will send you a password reset link.
      </p>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="filled"
          name="email"
          label="E-mail address"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          error={emailError !== null}
          helperText={emailError}
          fullWidth
        />
        <Space height={12} />
        <PushToRight>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            disableElevation
          >
            Submit
          </LoadingButton>
        </PushToRight>
      </form>
    </>
  );
};

export default PasswordResetForm;
