import RequireAuthentication from '../components/RequireAuthentication';
import AppWrapper from '../components/AppWrapper';
import { Outlet } from 'react-router-dom';

const RootLayout = () => (
  <RequireAuthentication>
    <AppWrapper>
      <Outlet />
    </AppWrapper>
  </RequireAuthentication>
);

export default RootLayout;
