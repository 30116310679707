import React, { useMemo } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import Space from '@/components/Space';
import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';

type NameFieldProps = TextFieldProps & {
  label: React.ReactNode;
};

const FieldWrapper = styled('div')({
  marginBottom: 6,
  width: 430,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ':last-child': {
    marginBottom: 0,
  },
  label: {
    color: grey[700],
    flex: 1,
  },
});

const NameField = ({ label, ...props }: NameFieldProps) => {
  const randomNumber = useMemo(() => Math.random(), []);

  const fieldId = `name-field-${randomNumber}`;

  return (
    <FieldWrapper>
      <label htmlFor={fieldId}>{label}</label>
      <Space width={12} />
      <TextField id={fieldId} placeholder="name" size="small" {...props} />
    </FieldWrapper>
  );
};

export default NameField;
