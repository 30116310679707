import egodactLogoDefault from './egodact-logo-default.svg';
import styled from '@emotion/styled';

const StyledImage = styled('img')({
  height: 40,
});

const EgodactLogo = (props: Record<string, unknown>) => (
  <StyledImage src={egodactLogoDefault} alt="Egodact logo" {...props} />
);

export default EgodactLogo;
