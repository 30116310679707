import styled from '@emotion/styled';
import { Tooltip, lighten } from '@mui/material';
import { ErrorOutline as ErrorIcon } from '@mui/icons-material';

const ErrorIndicator = styled(ErrorIcon)(({ theme }) => ({
  padding: 4,
  fontSize: 28,
  color: theme.palette.error.main,
  backgroundColor: lighten(theme.palette.error.main, 0.9),
  borderRadius: '50%',
}));

const IconRetrievalErrorIndicator = () => (
  <Tooltip title="Failed to load icon" arrow>
    <ErrorIndicator />
  </Tooltip>
);

export default IconRetrievalErrorIndicator;
