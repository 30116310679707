import { ButtonProps } from '@mui/material';
import DialogButton from './DialogButton';

const DialogCancelButton = ({ children, ...props }: ButtonProps) => (
  <DialogButton variant="outlined" color="secondary" {...props}>
    {children ?? 'Cancel'}
  </DialogButton>
);

export default DialogCancelButton;
