import { useEffect, useState } from 'react';

const readImageAsDataUrl = (imageBlob: Blob): Promise<string> => {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.onload = e => resolve(e.target?.result as string);
    reader.readAsDataURL(imageBlob);
  });
};

const useImageAsDataUrl = (image: Blob | null | undefined): string | null => {
  const [imageAsDataUrl, setImageAsDataUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!image) {
      setImageAsDataUrl(null);

      return;
    }

    const processImage = async () => {
      const dataUrl = await readImageAsDataUrl(image);

      setImageAsDataUrl(dataUrl);
    };

    processImage();
  }, [image]);

  return imageAsDataUrl;
};

export default useImageAsDataUrl;
