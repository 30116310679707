import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';

const ConfigGroupTitle = styled('h3')({
  marginTop: 24,
  color: grey[600],
  fontSize: 16,
  fontWeight: 400,
  ':first-of-type': {
    marginTop: 0,
  },
});

export default ConfigGroupTitle;
