import React from 'react';
import useAuthenticatedSuperUser from './AuthenticationState/useAuthenticatedSuperUser';
import SuperUserRole from '@/lib/constants/SuperUserRole';
import NotFoundContent from '@/routes/NotFound/NotFoundContent';

type EgodactOnlyRouteProps = {
  children: React.ReactNode;
};

const EgodactOnlyRoute = ({ children }: EgodactOnlyRouteProps) => {
  const authenticatedSuperUser = useAuthenticatedSuperUser();

  if (authenticatedSuperUser?.role !== SuperUserRole.EGODACT_USER) {
    return <NotFoundContent />;
  }

  return children;
};

export default EgodactOnlyRoute;
