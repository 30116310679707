import RandomColorAvatar from '@/components/RandomColorAvatar';
import { SuperUser } from '@/routes/super-users/types';
import ListItemWrapper from './ListItemWrapper';
import BasicInfoWrapper from './BasicInfoWrapper';
import DeleteSuperUser from './DeleteSuperUser';
import useAuthenticatedSuperUser from '@/components/AuthenticationState/useAuthenticatedSuperUser';
import RoleSelect from './RoleSelect';
import InviteSuperUser from './InviteSuperUser';

type SuperUserListItemProps = {
  superUser: SuperUser;
};

const SuperUserListItem = ({ superUser }: SuperUserListItemProps) => {
  const authenticatedSuperUser = useAuthenticatedSuperUser();

  const controlsDisabled =
    authenticatedSuperUser!.email === superUser.email || superUser.isRootUser;

  return (
    <ListItemWrapper>
      <RandomColorAvatar>
        {superUser.profile.firstName.charAt(0)}
      </RandomColorAvatar>
      <BasicInfoWrapper>
        <h3>{superUser.profile.name}</h3>
        <em>{superUser.email}</em>
      </BasicInfoWrapper>
      {superUser.invitable && <InviteSuperUser superUser={superUser} />}
      <RoleSelect superUser={superUser} disabled={controlsDisabled} />
      <DeleteSuperUser superUser={superUser} disabled={controlsDisabled} />
    </ListItemWrapper>
  );
};

export default SuperUserListItem;
