import { TextField, TextFieldProps } from '@mui/material';

type AllowedEmailTextFieldProps = TextFieldProps & {
  asPrefixField?: boolean;
};

const AllowedEmailTextField = ({
  asPrefixField,
  ...props
}: AllowedEmailTextFieldProps) => (
  <TextField
    variant="outlined"
    placeholder={asPrefixField ? 'Prefix' : 'E-mail domain'}
    size="small"
    fullWidth={!asPrefixField}
    style={{ maxWidth: asPrefixField ? 80 : undefined }}
    {...props}
  />
);

export default AllowedEmailTextField;
