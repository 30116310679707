import validator from 'validator';

const validateReference = (reference: string): string | null => {
  if (reference.trim().length === 0) {
    return 'Please enter a reference';
  }

  if (!validator.isAlphanumeric(reference, 'en-US', { ignore: '-' })) {
    return 'Reference may only contain letters, numbers and dashes (-)';
  }

  if (!validator.isLowercase(reference)) {
    return 'Reference must be lowercase';
  }

  return null;
};

export default validateReference;
