import styled from '@emotion/styled';

const CardWrapper = styled('section')({
  marginLeft: -6,
  marginRight: -6,
  marginBottom: 24,
  width: 'calc(100% + 12px)',
  display: 'flex',
});

export default CardWrapper;
