import { useState } from 'react';
import PasswordResetComplete from './PasswordResetComplete';
import PasswordResetForm from './PasswordResetForm';

type PasswordResetFlowProps = {
  token: string;
};

const PasswordResetFlow = ({ token }: PasswordResetFlowProps) => {
  const [passwordResetComplete, setPasswordResetComplete] = useState(false);

  if (passwordResetComplete) {
    return <PasswordResetComplete />;
  }

  return (
    <PasswordResetForm
      token={token}
      onComplete={() => setPasswordResetComplete(true)}
    />
  );
};

export default PasswordResetFlow;
