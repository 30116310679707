import { SchoolDataMigration } from '../../../types';
import styled from '@emotion/styled';
import moment from 'moment';
import RetryDataMigration from './RetryDataMigration';

type DataMigrationDetailsProps = {
  dataMigration: SchoolDataMigration;
};

const DataMigrationInfoLine = styled('div')(({ theme }) => ({
  marginBottom: 12,
  color: theme.palette.text.secondary,
  ':last-child': {
    marginBottom: 0,
  },
  'strong, code': {
    color: theme.palette.text.primary,
  },
}));

const DataMigrationDetails = ({ dataMigration }: DataMigrationDetailsProps) => (
  <>
    <DataMigrationInfoLine>
      Triggered by: <strong>{dataMigration.triggerer.profile.name}</strong>
    </DataMigrationInfoLine>
    {dataMigration.failedAt === null && dataMigration.finishedAt === null && (
      <DataMigrationInfoLine>
        Running at stage: <strong>{dataMigration.stage.toLowerCase()}</strong>
      </DataMigrationInfoLine>
    )}
    {dataMigration.failedAt !== null && (
      <>
        <DataMigrationInfoLine>
          Failed at: <strong>{moment(dataMigration.failedAt).format()}</strong>
        </DataMigrationInfoLine>
        <DataMigrationInfoLine>
          Stage of failure: <code>{dataMigration.stage.toLowerCase()}</code>
        </DataMigrationInfoLine>
        <DataMigrationInfoLine>
          <RetryDataMigration dataMigration={dataMigration} />
        </DataMigrationInfoLine>
      </>
    )}
    {dataMigration.finishedAt !== null && (
      <DataMigrationInfoLine>
        Finished at:{' '}
        <strong>{moment(dataMigration.finishedAt).format()}</strong>
      </DataMigrationInfoLine>
    )}
  </>
);

export default DataMigrationDetails;
