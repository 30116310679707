import React, { Dispatch, SetStateAction, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import Space from '@/components/Space';
import { LoadingButton } from '@mui/lab';
import api from '@/lib/api';
import ApiError from '@/lib/api/ApiError';
import validateEmail from '@/lib/validation/validateEmail';
import FlexWrapper from '@/components/FlexWrapper';
import ForgotPasswordLink from './ForgotPasswordLink';

type EmailPasswordFormProps = {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  onNext: () => void;
};

type LoginFormErrors = {
  email: string | null;
  password: string | null;
};

const EmailPasswordForm = ({
  email,
  setEmail,
  password,
  setPassword,
  onNext,
}: EmailPasswordFormProps) => {
  const [loginErrors, setLoginErrors] = useState<LoginFormErrors>({
    email: null,
    password: null,
  });
  const [genericLoginError, setGenericLoginError] = useState<string | null>(
    null,
  );

  const [loading, setLoading] = useState(false);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    const loginErrors = {
      email: validateEmail(email),
      password: password.length === 0 ? 'Please enter your password' : null,
    };

    setLoginErrors(loginErrors);

    if (loginErrors.email !== null || loginErrors.password !== null) {
      return;
    }

    setLoading(true);

    try {
      await api.post('/auth/sign-in/password', {
        email,
        password,
      });

      onNext();
    } catch (error) {
      if (!(error instanceof ApiError)) {
        throw error;
      }

      setGenericLoginError(error.getErrorCode());
    }

    setLoading(false);
  };

  return (
    <>
      <h1>Sign in to Egodact Admin</h1>
      <Space height={24} />
      {genericLoginError && (
        <>
          <Alert severity="error">{genericLoginError}</Alert>
          <Space height={12} />
        </>
      )}
      <form onSubmit={handleLogin}>
        <TextField
          variant="filled"
          name="email"
          label="E-mail address"
          value={email}
          onChange={e => setEmail(e.target.value)}
          error={loginErrors.email !== null}
          helperText={loginErrors.email}
          fullWidth
        />
        <Space height={12} />
        <TextField
          variant="filled"
          name="password"
          label="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          error={loginErrors.password !== null}
          helperText={loginErrors.password}
          type="password"
          fullWidth
        />
        <Space height={12} />
        <FlexWrapper alignItemsCenter>
          <ForgotPasswordLink />
          <Space width="auto" />
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            disableElevation
          >
            Next
          </LoadingButton>
        </FlexWrapper>
      </form>
    </>
  );
};

export default EmailPasswordForm;
