import { useState } from 'react';
import MfaQrCode from './MfaQrCode';
import MfaOtpConfirm from './MfaOtpConfirm';

type MfaSetupProps = {
  inviteToken: string;
  onFinish: () => void;
};

enum MfaSetupStage {
  QR_CODE,
  OTP_CONFIRM,
}

const MfaSetup = ({ inviteToken, onFinish }: MfaSetupProps) => {
  const [stage, setStage] = useState(MfaSetupStage.QR_CODE);

  if (stage === MfaSetupStage.QR_CODE) {
    return (
      <MfaQrCode
        inviteToken={inviteToken}
        onDone={() => setStage(MfaSetupStage.OTP_CONFIRM)}
      />
    );
  }

  return (
    <MfaOtpConfirm
      inviteToken={inviteToken}
      onGoBack={() => setStage(MfaSetupStage.QR_CODE)}
      onDone={onFinish}
    />
  );
};

export default MfaSetup;
