import styled from '@emotion/styled';

const TextFieldLabel = styled('label')({
  marginRight: 12,
  width: 110,
  height: 40,
  fontWeight: 500,
  fontSize: 16,
  display: 'inline-flex',
  alignItems: 'center',
});

export default TextFieldLabel;
