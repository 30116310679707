import React, { useState } from 'react';
import { Alert, TextField } from '@mui/material';
import validatePassword from '@/lib/validation/validatePassword';
import validatePasswordConfirmation from '@/lib/validation/validatePasswordConfirmation';
import api from '@/lib/api';
import ApiError from '@/lib/api/ApiError';
import Space from '@/components/Space';
import PushToRight from '@/components/PushToRight';
import { LoadingButton } from '@mui/lab';

type PasswordResetFormProps = {
  token: string;
  onComplete: () => void;
};

type FormErrors = {
  password: string | null;
  passwordConfirmation: string | null;
};

const PasswordResetForm = ({ token, onComplete }: PasswordResetFormProps) => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [formErrors, setFormErrors] = useState<FormErrors>({
    password: null,
    passwordConfirmation: null,
  });

  const [submitting, setSubmitting] = useState(false);
  const [genericSubmitError, setGenericSubmitError] = useState<string | null>(
    null,
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setGenericSubmitError(null);

    const formErrors = {
      password: validatePassword(password),
      passwordConfirmation: validatePasswordConfirmation(
        password,
        passwordConfirmation,
      ),
    };

    setFormErrors(formErrors);

    if (
      formErrors.password !== null ||
      formErrors.passwordConfirmation !== null
    ) {
      return;
    }

    setSubmitting(true);

    try {
      await api.post('auth/password-reset', {
        token,
        password,
      });

      onComplete();
    } catch (error) {
      if (!(error instanceof ApiError)) {
        throw error;
      }

      setGenericSubmitError(error.getErrorCode());
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <h1>Reset your password</h1>
      <p>Enter the new password your want to use below.</p>
      {genericSubmitError !== null && (
        <>
          <Alert severity="error">
            Could not reset password: {genericSubmitError}
          </Alert>
          <Space height={12} />
        </>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          variant="filled"
          name="password"
          label="Password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
          error={formErrors.password !== null}
          helperText={formErrors.password}
          type="password"
          fullWidth
        />
        <Space height={12} />
        <TextField
          variant="filled"
          name="password"
          label="Password confirmation"
          value={passwordConfirmation}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPasswordConfirmation(e.target.value)
          }
          error={formErrors.passwordConfirmation !== null}
          helperText={formErrors.passwordConfirmation}
          type="password"
          fullWidth
        />
        <Space height={12} />
        <PushToRight>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submitting}
            disableElevation
          >
            Reset password
          </LoadingButton>
        </PushToRight>
      </form>
    </>
  );
};

export default PasswordResetForm;
