import styled from '@emotion/styled';

const PerspectiveCohortListItem = styled('li')(({ theme }) => ({
  padding: '12px 16px',
  display: 'flex',
  alignItems: 'center',
  borderBottom: `2px solid ${theme.palette.divider}`,
  ':last-child': {
    borderBottom: 'none',
  },
  h3: {
    marginRight: 'auto',
    fontSize: 16,
  },
}));

export default PerspectiveCohortListItem;
