import { School } from '../schools/types';

export enum SchoolDataMigrationStage {
  QUEUED = 'QUEUED',
  INSTANCE_CONFIG = 'INSTANCE_CONFIG',
  USERS = 'USERS',
  GROUPS = 'GROUPS',
  PORTFOLIO_TEMPLATES = 'PORTFOLIO_TEMPLATES',
  CHALLENGE_MODELS = 'CHALLENGE_MODELS',
  ROWS = 'ROWS',
  CHALLENGES = 'CHALLENGES',
  TASKS = 'TASKS',
  PORTFOLIOS = 'PORTFOLIOS',
  PORTFOLIO_SNAPSHOTS = 'PORTFOLIO_SNAPSHOTS',
}

export type SchoolDataMigration = {
  id: number;
  stage: SchoolDataMigrationStage;
  finishedAt: string;
  failedAt: string;
  triggerer: {
    email: string;
    profile: {
      firstName: string;
      name: string;
    };
  };
  targetSchool: Omit<School, '_count'>;
};
