import styled from '@emotion/styled';
import {
  CONNECTOR_WRAPPER_WIDTH,
  CONNECTOR_THICKNESS,
  CONNECTOR_COLOR,
  HIGHLIGHTED_CONNECTOR_COLOR,
} from './styleConstants';
import {
  CARD_HEIGHT,
  CARD_MARGIN_BOTTOM,
} from '../../PerspectiveCard/styleConstants';

type LowerCardConnectorProps = {
  index: number;
  parentCardIndex: number;
  highlighted: boolean;
};

const LowerCardConnector = styled('div')<LowerCardConnectorProps>(
  ({ index, parentCardIndex, highlighted }) => ({
    position: 'absolute',
    top:
      (parentCardIndex + 1) * CARD_HEIGHT +
      parentCardIndex * CARD_MARGIN_BOTTOM -
      CARD_HEIGHT / 2 -
      CONNECTOR_THICKNESS / 2,
    left: 0,
    width: '100%',
    height:
      (index + 1) * CARD_HEIGHT +
      index * CARD_MARGIN_BOTTOM -
      ((parentCardIndex + 1) * CARD_HEIGHT +
        parentCardIndex * CARD_MARGIN_BOTTOM) +
      CONNECTOR_THICKNESS,
    boxSizing: 'border-box',
    zIndex: highlighted ? 2 : 1,
    '::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: `calc(50% + ${CONNECTOR_THICKNESS / 2}px)`,
      height: '50%',
      boxSizing: 'border-box',
      borderRight: `${CONNECTOR_THICKNESS}px solid ${CONNECTOR_COLOR}`,
      borderTop: `${CONNECTOR_THICKNESS}px solid ${CONNECTOR_COLOR}`,
      borderTopRightRadius: CONNECTOR_WRAPPER_WIDTH / 2,
      borderColor: highlighted ? HIGHLIGHTED_CONNECTOR_COLOR : undefined,
    },
    '::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      right: 0,
      width: `calc(50% + ${CONNECTOR_THICKNESS / 2}px)`,
      height: '50%',
      boxSizing: 'border-box',
      borderLeft: `${CONNECTOR_THICKNESS}px solid ${CONNECTOR_COLOR}`,
      borderBottom: `${CONNECTOR_THICKNESS}px solid ${CONNECTOR_COLOR}`,
      borderBottomLeftRadius: CONNECTOR_WRAPPER_WIDTH / 2,
      borderColor: highlighted ? HIGHLIGHTED_CONNECTOR_COLOR : undefined,
    },
  }),
);

export default LowerCardConnector;
