import React, { useState } from 'react';
import MainPageTitle from '@/components/MainPageTitle';
import Card from '@/components/Card';
import PushToRight from '@/components/PushToRight';
import { LoadingButton } from '@mui/lab';
import Space from '@/components/Space';
import SuperUserRole from '@/lib/constants/SuperUserRole';
import FlexWrapper from '@/components/FlexWrapper';
import RoleSelect from './RoleSelect';
import { Alert, SelectChangeEvent } from '@mui/material';
import validateEmail from '@/lib/validation/validateEmail';
import api from '@/lib/api';
import ApiError from '@/lib/api/ApiError';
import { useNavigate } from 'react-router-dom';
import AppTitle from '@/components/AppTitle';

type FormErrors = {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
};

const CreateSuperUser = () => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState(SuperUserRole.LEIDEN_USER);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [formErrors, setFormErrors] = useState<FormErrors>({
    email: null,
    firstName: null,
    lastName: null,
  });

  const [saving, setSaving] = useState(false);
  const [genericSaveError, setGenericSaveError] = useState<null | string>(null);

  const navigate = useNavigate();

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();

    setGenericSaveError(null);

    const formErrors = {
      email: validateEmail(email),
      firstName:
        firstName.trim().length === 0 ? 'Please enter a first name' : null,
      lastName:
        lastName.trim().length === 0 ? 'Please enter a last name' : null,
    };

    setFormErrors(formErrors);

    if (
      formErrors.email !== null ||
      formErrors.firstName !== null ||
      formErrors.lastName !== null
    ) {
      return;
    }

    setSaving(true);

    try {
      await api.post('/users', {
        email,
        firstName,
        name: `${firstName} ${lastName}`,
        role,
      });

      navigate('/users');
    } catch (error) {
      if (!(error instanceof ApiError)) {
        throw error;
      }

      setGenericSaveError(error.getErrorCode());
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <AppTitle>New user</AppTitle>
      <MainPageTitle>New user</MainPageTitle>
      {genericSaveError !== null && (
        <>
          <Alert severity="error">
            Could not save user: {genericSaveError}
          </Alert>
          <Space height={24} />
        </>
      )}
      <form onSubmit={handleSave}>
        <Card>
          <Card.Title>Basic user information</Card.Title>
          <FlexWrapper>
            <Card.TextField
              label="E-mail address"
              name="email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              error={formErrors.email !== null}
              helperText={formErrors.email}
              disabled={saving}
              fullWidth
            />
            <RoleSelect
              value={role}
              onChange={(e: SelectChangeEvent<SuperUserRole>) =>
                setRole(e.target.value as SuperUserRole)
              }
              disabled={saving}
            />
          </FlexWrapper>
        </Card>
        <Card>
          <Card.Title>Personal data</Card.Title>
          <Card.TextField
            label="First name"
            name="first_name"
            value={firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFirstName(e.target.value)
            }
            error={formErrors.firstName !== null}
            helperText={formErrors.firstName}
            disabled={saving}
            width="50%"
          />
          <Space height={12} />
          <Card.TextField
            label="Last name"
            name="last_name"
            value={lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setLastName(e.target.value)
            }
            error={formErrors.lastName !== null}
            helperText={formErrors.lastName}
            disabled={saving}
            width="50%"
          />
        </Card>
        <PushToRight>
          <LoadingButton variant="contained" loading={saving} type="submit">
            Create
          </LoadingButton>
        </PushToRight>
      </form>
    </>
  );
};

export default CreateSuperUser;
