import Space from '@/components/Space';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const AccountSetupComplete = () => (
  <>
    <h1>Done!</h1>
    <p>You have successfully set up your account.</p>
    <Space height={12} />
    <Button
      variant="contained"
      disableElevation
      component={Link}
      to="/auth/login"
    >
      Sign in
    </Button>
  </>
);

export default AccountSetupComplete;
