import { Perspective } from '@/routes/perspectives/types';
import NewPerspectiveIconUpload from './NewPerspectiveIconUpload';
import PerspectiveIconManager from './PerspectiveIconManager';

type PerspectiveIconProps = {
  perspective: Perspective;
};

const PerspectiveIcon = ({ perspective }: PerspectiveIconProps) => {
  if (perspective.icon === null) {
    return <NewPerspectiveIconUpload perspective={perspective} />;
  }

  return <PerspectiveIconManager perspective={perspective} />;
};

export default PerspectiveIcon;
