import useSwr from '@/lib/useSwr';
import { SuperUser } from '../../types';
import LoadingSpinner from '@/components/LoadingSpinner';
import { Alert } from '@mui/material';
import ListWrapper from './ListWrapper';
import SuperUserListItem from './SuperUserListItem';

export type SuperUsersMap = {
  [id: number]: SuperUser;
};

const SuperUserList = () => {
  const { isLoading, data: superUsers, error } = useSwr<SuperUsersMap>(
    '/users',
  );

  if (isLoading) {
    return <LoadingSpinner margin={48} centered />;
  }

  if (error || !superUsers) {
    return (
      <Alert severity="error">
        Something went wrong while trying to load users.
      </Alert>
    );
  }

  return (
    <ListWrapper>
      {Object.values(superUsers).map(superUser => (
        <SuperUserListItem superUser={superUser} key={superUser.id} />
      ))}
    </ListWrapper>
  );
};

export default SuperUserList;
