import styled from '@emotion/styled';
import { Paper } from '@mui/material';

const PaperAsArticle = (props: Record<string, unknown>) => (
  <Paper component="article" {...props} />
);

const SchoolStatCard = styled(PaperAsArticle)({
  margin: 6,
  padding: 18,
  lineHeight: 1,
  flex: 1,
});

export default SchoolStatCard;
