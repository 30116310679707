import { PerspectiveCohortWithPerspectives } from '../types';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useState } from 'react';
import Dialog from '@/components/Dialog';
import { useNavigate } from 'react-router-dom';
import api from '@/lib/api';
import { LoadingButton } from '@mui/lab';

type DeletePerspectiveCohortProps = {
  perspectiveCohort: PerspectiveCohortWithPerspectives;
};

// TODO in the future this should use soft delete
const DeletePerspectiveCohort = ({
  perspectiveCohort,
}: DeletePerspectiveCohortProps) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const navigate = useNavigate();

  const handleDelete = async () => {
    setConfirmationDialogOpen(false);

    setDeleting(true);

    await api.delete(`/perspectives/cohorts/${perspectiveCohort.id}`);

    setDeleting(false);

    navigate('/perspectives');
  };

  if (
    perspectiveCohort.publishAt !== null ||
    perspectiveCohort.perspectives.length > 0
  ) {
    return null;
  }

  return (
    <>
      <LoadingButton
        variant="contained"
        color="error"
        startIcon={<DeleteIcon />}
        loadingPosition="start"
        onClick={() => setConfirmationDialogOpen(true)}
        loading={deleting}
        disableElevation
      >
        Delete cohort
      </LoadingButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>Are you sure?</Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <strong>{perspectiveCohort.name}</strong> will become permanently
            unavailable for all Egodact users. This action cannot be reversed.
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={handleDelete} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeletePerspectiveCohort;
