import useAuthenticatedSuperUser from '@/components/AuthenticationState/useAuthenticatedSuperUser';
import AuthPageWrapper from '@/components/AuthPageWrapper';
import AppWrapper from '@/components/AppWrapper';
import HomeButton from './HomeButton';
import Space from '@/components/Space';
import NotFoundContent from './NotFoundContent';

const NotFoundPage = () => {
  const authenticatedSuperUser = useAuthenticatedSuperUser();

  if (authenticatedSuperUser === null) {
    return (
      <AuthPageWrapper>
        <h1>Page not found</h1>
        <Space height={24} />
        <HomeButton />
      </AuthPageWrapper>
    );
  }

  return (
    <AppWrapper>
      <NotFoundContent />
    </AppWrapper>
  );
};

export default NotFoundPage;
