import { InstanceConfig } from '@/routes/schools/types';
import useSyncedState from '@/lib/useSyncedState';
import InstanceConfigContext from './InstanceConfigContext';
import BooleanConfigValueEditor from './BooleanConfigValueEditor';
import { FormGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Save as SaveIcon } from '@mui/icons-material';
import PushToRight from '@/components/PushToRight';
import useAllowedEmailsState from './useAllowedEmailsState';
import AllowedEmailManager from './AllowedEmailManager';
import ConfigGroupTitle from './ConfigGroupTitle';
import AuthProviderSelect from './AuthProviderSelect';
import CustomChallengeNamingManager from './CustomChallengeNamingManager';
import { useState } from 'react';
import api from '@/lib/api';
import { SchoolWithStats } from '@/routes/schools/types';
import useSwrMutate from '@/lib/useSwrMutate';

type InstanceConfigEditorProps = {
  school: SchoolWithStats;
  instanceConfig: InstanceConfig;
};

const InstanceConfigEditor = ({
  school,
  instanceConfig: originalInstanceConfig,
}: InstanceConfigEditorProps) => {
  const [instanceConfig, setInstanceConfig] = useSyncedState(
    originalInstanceConfig,
  );
  const [saving, setSaving] = useState(false);

  const {
    allowedEmails,
    allowedEmailsAsObject: newAllowedEmails,
    addAllowedEmail,
    editAllowedEmail,
    removeAllowedEmail,
  } = useAllowedEmailsState(instanceConfig.allowedEmails);

  const mutate = useSwrMutate<InstanceConfig>(
    `/schools/${school.id}/instance-config`,
  );

  const instanceConfigHasChanged =
    JSON.stringify(Object.values(newAllowedEmails)) !==
      JSON.stringify(instanceConfig.allowedEmails) ||
    JSON.stringify(originalInstanceConfig) !== JSON.stringify(instanceConfig);

  const handleSave = async () => {
    setSaving(true);

    const newInstanceConfig = {
      ...instanceConfig,
      allowedEmails: Object.values(newAllowedEmails),
    };

    const updatedInstanceConfig = await api.put(
      `/schools/${school.id}/instance-config`,
      {
        instanceConfigValues: Object.entries(newInstanceConfig).map(
          ([reference, value]) => ({
            reference,
            value: JSON.stringify(value),
          }),
        ),
      },
    );

    mutate(instanceConfig => ({
      ...instanceConfig,
      ...updatedInstanceConfig,
    }));

    setSaving(false);
  };

  return (
    <InstanceConfigContext.Provider value={[instanceConfig, setInstanceConfig]}>
      <AllowedEmailManager
        allowedEmails={allowedEmails}
        addAllowedEmail={addAllowedEmail}
        editAllowedEmail={editAllowedEmail}
        removeAllowedEmail={removeAllowedEmail}
      />
      <AuthProviderSelect />
      <CustomChallengeNamingManager />
      <ConfigGroupTitle>Content settings</ConfigGroupTitle>
      <FormGroup>
        <BooleanConfigValueEditor
          label="Enable perspective module"
          configKey="enablePerspectiveModule"
        />
        <BooleanConfigValueEditor
          label="Use right-to-left sliders"
          configKey="useReversedSliders"
        />
        <BooleanConfigValueEditor
          label="Enable arrows for selecting users in the coach mode"
          configKey="enableUserSwitcherArrows"
        />
        <BooleanConfigValueEditor
          label="Use profile pictures to identify users"
          configKey="useProfilePictures"
        />
        <BooleanConfigValueEditor
          label="Enable more extensive rubric collapsing controls"
          configKey="enableAggressiveRubricCollapsing"
        />
      </FormGroup>
      <PushToRight>
        <LoadingButton
          variant="contained"
          startIcon={<SaveIcon />}
          loadingPosition="start"
          onClick={handleSave}
          loading={saving}
          disabled={!instanceConfigHasChanged}
          disableElevation
        >
          Save
        </LoadingButton>
      </PushToRight>
    </InstanceConfigContext.Provider>
  );
};

export default InstanceConfigEditor;
