import styled from '@emotion/styled';
import { ButtonBase } from '@mui/material';

const AddButton = styled(ButtonBase)(({ theme }) => ({
  flex: 1,
  font: 'inherit',
  fontWeight: 500,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '.MuiSvgIcon-root': {
    width: 36,
    height: 36,
    color: theme.palette.primary.dark,
    transition: theme.transitions.create(['transform']),
  },
  ':hover .MuiSvgIcon-root, :focus .MuiSvgIcon-root': {
    transform: 'scale(1.1)',
  },
  h4: {
    marginTop: 12,
    lineHeight: 1,
  },
}));

export default AddButton;
