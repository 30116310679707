import validator from 'validator';

const validateEmail = (email: string): string | null => {
  if (email.trim().length === 0) {
    return 'Please enter an e-mail address';
  }

  if (!validator.isEmail(email)) {
    return 'Please enter a valid e-mail address';
  }

  return null;
};

export default validateEmail;
