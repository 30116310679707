import { useEffect } from 'react';

const useKeydownListener = (
  targetElement: HTMLElement | null,
  keydownHandler: (event: KeyboardEvent) => void,
): void => {
  useEffect(() => {
    if (targetElement !== null) {
      targetElement.addEventListener('keydown', keydownHandler);

      return () => targetElement.removeEventListener('keydown', keydownHandler);
    }
  });
};

export default useKeydownListener;
