import React from 'react';
import { Helmet } from 'react-helmet';

type AppTitleProps = {
  children: React.ReactNode;
};

const AppTitle = ({ children }: AppTitleProps) => (
  <Helmet>
    <title>{children}</title>
  </Helmet>
);

export default AppTitle;
