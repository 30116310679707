import styled from '@emotion/styled';

const EditorTitle = styled('h3')({
  marginRight: 12,
  height: 40,
  fontSize: 16,
  display: 'inline-flex',
  alignItems: 'center',
});

export default EditorTitle;
