import AppTitle from '@/components/AppTitle';
import AuthPageWrapper from '@/components/AuthPageWrapper';
import HomeButton from '../NotFound/HomeButton';
import ErrorScreenTitle from './ErrorScreenTitle';

const AuthErrorScreen = () => (
  <>
    <AppTitle>Something went wrong</AppTitle>
    <AuthPageWrapper>
      <ErrorScreenTitle>Something went wrong</ErrorScreenTitle>
      <p>An unknown error occurred. Egodact has been informed.</p>
      <HomeButton />
    </AuthPageWrapper>
  </>
);

export default AuthErrorScreen;
